import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Inventario } from 'src/app/models/inventario';
import { InventarioService } from 'src/app/services/Inventory/inventario.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.scss']
})
export class InventarioComponent implements OnInit {
  
  public page = 1;
  public count = 0;
  public pageSize = 3;
  public pageSizes = [3, 5, 10];
  public search:string;
  public products:Inventario[] = [];
  constructor(private _router: Router,
    private _inventarioService: InventarioService,private spinner: NgxSpinnerService) { 
      this.spinner.show();
      this.getProducts();
      this.spinner.hide();
    }

  ngOnInit(): void {
  }
  
  
  getProducts(){
    let request = {
      search:this.search,
      page:this.page,
      pageSize:this.pageSize
    };
    this._inventarioService.getProducts(request).subscribe(
      response => {
        this.count = response.totalItems,
        this.products = response.products;
        console.log(this.products);

      },
      error => {
        console.log(error);
      }
    );
  }
  handleSearch(search:string){
    this.search= search;
    this.getProducts();
  }
  handlePageChange(page:number) {
    this.page = page;
    this.getProducts();
  }

  handlePageSizeChange(pageSize:number) {
    this.pageSize = pageSize;
    this.page = 1;
    this.getProducts();
  }
}
