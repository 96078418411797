import { ClientesService } from 'src/app/services/Clientes/clientes.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { global } from './../../services/global';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';
import { AngularFileUploaderConfig } from 'angular-file-uploader';
import { VideoService } from 'src/app/services/Video/video.service';
import { Video } from 'src/app/models/video';

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.scss']
})
export class UploadVideoComponent implements OnInit {

  public file: any;
  public label: string = 'Selecciona Archivo';
  public resetVar: boolean;
  public status: string;
  public message: string;

  public videos: Video;
  public url_videos: string = global.url_video;

  public afuConfig = {
    multiple: false,
    formatsAllowed: ".mov, .mp4, .ovi, .mkv, .mpg, .webm",
    maxSize: "150",
    uploadAPI: {
      url: global.url + 'file',
      method: "POST",
      headers: {
        //  "Authorization" : `Bearer ${token}`
      }
    },
    //theme: "attachPin",
    hideProgressBar: false, 
    hideResetBtn: true,
    hideSelectBtn: false,
    fileNameIndex: true,
    replaceTexts: {
      selectFileBtn: 'Seleccionar archivos',
      resetBtn: 'Reset',
      uploadBtn: 'Subir Archivo',
      dragNDropBox: 'attachPin',
      attachPinBtn: 'Subir archivo    ',
      afterUploadMsg_success: 'Carga de datos satisfactoria !',
      afterUploadMsg_error: 'Ups, Algo ha salido mal !',
      sizeLimit: 'Límite de memoria'
    }
  };

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private clientService: ClientesService,
    private videoService: VideoService
  ) { 
    this.status = '';
    this.message = '';
    this.resetVar = false;
  }

  ngOnInit(): void {
    this.spinner.show()
    this.getVieos();
    //this.reloadCurrentRoute();
  }

  reloadCurrentRoute() {
    //let currentUrl = this.router.url;
    this.router.navigateByUrl('/home', {skipLocationChange: true}).then(() => {
        this.router.navigate(['home/video']);
    });
  }


  public onSubmitData(form: any) {
    this.label = this.file;
    console.log('Dato enviado', this.file);
  }
  public onLoadSpinner(data: any) {
    //this.spinner.show();
    //console.log("Entramos a donde quiero" ,data);
  }

  public dataUpload(datos: any) {
    console.log('info datos',  datos)
    if (datos.body.code = 200) {
      // Acá va todo bien
      this.status = datos.body.status;
      this.message = datos.body.message;
      this.resetVar = true;
      //this.clientService.flagToChangeData(true);
      this.spinner.hide();
      //this.ngOnInit();
      this.reloadCurrentRoute();
      //.then(() => {
      //   sessionStorage.removeItem('cliente');
      // }, error => {
      //     console.log('Ups, ha habido un error: ', error);
      // });
    } else {
      this.status = 'error';
      this.spinner.hide();
    }
    //console.log(datos);
    
  }

// traer todos los videos
public getVieos() {
  this.videoService.getAllVideos().subscribe(res => {
    this.spinner.hide()

    this.videos = res.videos;
    console.log("Esto es lo que trae la rta: ", res);
    console.log("Estos son los videos", this.videos);

  }, error => {
    console.log("Ups, ha ocurrido un error: ", error);
    this.spinner.hide();
  });
}

selecVido: Video[];
videoActivo: Video[];
public onSelectClient(item: any){
  let objetoVideo;
  let idVideoActivo;
  this.selecVido = item;
  let idVideoSelect;
  console.log("video selesccionado", item);
  console.log("estdodel video seleccionado",  this.selecVido['estado'])
  idVideoSelect = this.selecVido['id'];
  console.log("Id video seleccionado",  idVideoSelect)

  this.videoService.getVideoAct(1).subscribe(res => {

    objetoVideo = res.video;
    let request_v2 = {
       estado: 1
    };
    let request_v = {
       estado: 0
     };

    if( objetoVideo  == null) {
      //console.log('Estado nulo');
       this.videoService.updataVideo(idVideoSelect, request_v2).subscribe(res => {
       //console.log("Respuesta de cambo de estado", res);
       });
    }else {

      idVideoActivo = res.video.id;
      console.log("Id video activo", idVideoActivo);

      //Cambia el estado del video activo
      this.videoService.updataVideo(idVideoActivo, request_v).subscribe(res => {
        console.log("Respuesta de cambo de estado", res);
      });

      //Cambia el estado del video selecionado
      this.videoService.updataVideo(idVideoSelect, request_v2).subscribe(res => {
       console.log("Respuesta de cambo de estado", res);
       this.spinner.show();
       this.reloadCurrentRoute();
       this.spinner.hide();
     });
     //this.getVieos();

     
    }
    
     });
  
}


  // ngOnDestroy(){
  //   console.log("Ondestroy On");
  // }


}
