import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from 'src/app/services/User/user.service';
import { User } from 'src/app/models/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent implements OnInit, AfterViewInit {

  public status: string;
  public message: string;
  public user: User;

  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.status = '';
    this.message = '';
    this.user = new User(1, '', '', 1, 0, '', '', '');
  }

  ngOnInit(): void {
    this.spinner.show();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.spinner.hide();
  }

  public onSubmitRegister(form) {
    this.spinner.show();
    console.log(this.user);
    this.userService.register(this.user).subscribe(
      response => {
        if (response.status == 'success') {
          this.status = response.status;
          this.spinner.hide();
          // form.reset();
        } else {
          this.spinner.hide();
          this.status = response.status;
        }
      }, error => {
        if (error.error.error.email[0]) {
          this.spinner.hide();
          this.status = 'error';
          //console.log("Este es status: ", this.status);
          this.message = "Ese correo electrónico ya está registrado";
          //console.log("Ese correo electrónico ya está registrado");
        }
        this.status = 'error';
        this.message = "No se ha podido crear el usuario.";
        this.spinner.hide();
        //console.log("Ups, ha habido un error: ", error.error.error);
      });
  }

  public goToDashboard() {
    this.router.navigate(['home/dashboard']);
  }

}
