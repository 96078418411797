import { DemoMaterialModule } from './material-module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IntroComponent } from './pages/intro/intro.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DetalleProductoComponent } from './pages/detalle-producto/detalle-producto.component';
import { DetalleCompraComponent } from './pages/detalle-compra/detalle-compra.component';
import { ListaProductosComponent } from './pages/lista-productos/lista-productos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { UserRegisterComponent } from './pages/user-register/user-register.component';
import { DataImportComponent } from './pages/data-import/data-import.component';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { UserListComponent } from './pages/user-list/user-list.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ToastrModule } from 'ngx-toastr';
import { InventarioComponent } from './pages/inventario/inventario.component';
import { InventarioListComponent } from './pages/inventario-list/inventario-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MultiComponent } from './pages/multi/multi.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TrmComponent } from './pages/trm/trm.component';
import { UploadVideoComponent } from './pages/upload-video/upload-video.component';

@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    SearchPageComponent,
    DashboardComponent,
    DetalleProductoComponent,
    DetalleCompraComponent,
    ListaProductosComponent,
    HomeComponent,
    NotFoundComponent,
    UserRegisterComponent,
    DataImportComponent,
    UserListComponent,
    InventarioComponent,
    InventarioListComponent,
    MultiComponent,
    TrmComponent,
    UploadVideoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    AngularFileUploaderModule,
    NgApexchartsModule,
    NgxPaginationModule,
    ToastrModule.forRoot(
      // {
      // disableTimeOut: true
      // }
    ),
    NgMultiSelectDropDownModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule {

}
