import { crmContact } from './../../models/crmContact';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Clientes } from 'src/app/models/clientes';
import { FormControl, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ClientesService } from 'src/app/services/Clientes/clientes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CrmService } from 'src/app/services/crmIntegration/crm.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/User/user.service';
import { global } from './../../services/global';
import { VideoService } from 'src/app/services/Video/video.service';
import { Video } from 'src/app/models/video';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {
  @ViewChild("wargingModal") modalContent: TemplateRef<any>;
  @ViewChild("listContacts") modalListUsers: TemplateRef<any>;
  @ViewChild("askRegisterContact") modalAskRegisterContact: TemplateRef<any>;
  @ViewChild("registerContact") modalRegisterContact: TemplateRef<any>;
  @ViewChild("contactHistroy") contacHistory: TemplateRef<any>;
  @ViewChild("creactionSatisfactoria") creactionSatisfactoria: TemplateRef<any>;
  @ViewChild("agregarHistoriaContacto") agregarHistoriaContacto: TemplateRef<any>;

  public url_videos: string = global.url_video;
  public videoact: string;
  public videoAct: Video;
  public datavideo: any;
  public identity;
  public variable;
  public status: string;
  public myControl = new FormControl();
  public clientes: Clientes[];
  public clientes_new: Clientes[];
  public filteredOptions: Observable<Clientes[]>;
  public spinLoader: boolean = false;
  public secondSpinLoader: boolean = false;
  public options: NgbModalOptions;
  public clientsFromCrm;
  public flagRegisterContact: boolean = false;
  public token: string;
  public historyContact: crmContact;
  public historyRegister;
  public telefono:string;
  public registerForm = this.fb.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', Validators.required],
    telefono:['',Validators.required],
    user_description: ['', Validators.required],
    tag_description: ['', Validators.required],
    event_description: ['', Validators.required]
  });
  selectedItems = [];
  public addHistoryContact = this.fb.group({
    id: ['', Validators.required],
    tag_description: ['', Validators.required],
    event_description: ['', Validators.required]
  });

  constructor(
    private clientService: ClientesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crmService: CrmService,
    private modal: NgbModal,
    private fb: FormBuilder,
    public _userService: UserService,
    private videoService: VideoService
  ) {
    this.identity = this._userService.getIdentity();
    this.variable = '';
    this.status = '';
    this.historyContact = new crmContact('', '', '', '',"");
    this.historyRegister = '';
    this.options = {
      size: 'md',
      centered: true,
      scrollable: true,
      windowClass: 'modal__class'
    };
  }

  public register() {
    this.modal.dismissAll();
    this.modal.open(this.modalRegisterContact, { size: 'xl', scrollable: true, centered: true, windowClass: 'modal__class' });
  }

  public successBtn() {
    this.spinLoader = true;
    if (this.variable == '') {
      this.spinLoader = false;
      this.modal.open(this.modalContent, this.options);
      setTimeout(() => {
        this.modal.dismissAll();
      }, 3000);
    } else {
      this.crmService.requestToken().subscribe(res => {
        this.token = res.access_token;
        this.crmService.getContanctFromCrm(this.variable, this.token).subscribe(res => {
          console.log("Esta es la rta desde get Client: ", res);

          // // CASO 1. Si res esta vacía: En este caso debo mostrar un modal y preguntar si se quiere crear un registro en el crm con ese contacto. 
          if (res.length == 0) {
            // Modal para preguntar si quiere registrar usuario.
            this.modal.open(this.modalAskRegisterContact, { size: 'md', centered: true, scrollable: true, windowClass: 'modal__class' });
            this.spinLoader = false;
            console.log("Esta vacía");
          } else {
            // Modal que despliega la lista de usuarios.
            this.spinLoader = false;
            this.clientsFromCrm = res;
            this.modal.open(this.modalListUsers, { size: 'xl', centered: true, scrollable: true, windowClass: 'modal__class' });
            console.log("No está vacía");
          }
        }, error => {
          this.spinLoader = false;
          this.modal.open(this.modalContent, this.options);
          console.log("Ups, ha habido un error: ", error);
        });
      });
    }

    // Buscar si el cliente con ese nombre y apellido existe. 
    // setTimeout(() => {
    //   this.spinLoader = false;
    // }, 20000);
  }

  public contactHistory(data: any) {
    this.secondSpinLoader = true;
    this.historyContact = data;
    console.log("Esta es la data que recibo: ", this.historyContact);
    this.crmService.requestToken().subscribe(res => {
      this.token = res.access_token;
      this.crmService.getHistoryContact(this.historyContact.id, this.token).subscribe(res => {
        console.log("Esta es la data que recibo despuès: ", res);
        this.historyRegister = res;
        this.modal.open(this.contacHistory, { size: 'xl', centered: true, scrollable: true, windowClass: 'modal__class' });
        this.secondSpinLoader = false;
      }, error => {
        console.log("Ups, ha habido un error: ", error);
        setTimeout(() => {
          this.modal.dismissAll();
        });
      });
    }, error => {
      console.log("Ups, ha habido un error: ", error);
      setTimeout(() => {
        this.modal.dismissAll();
      });
    });
  }

  public openAddHistory() {
    this.modal.open(this.agregarHistoriaContacto, { size: 'xl', centered: true, scrollable: true, windowClass: 'modal__class' });
  }

  public addHistory(form: any, formDirective: FormGroupDirective) {
    let id = this.historyContact.id;
    this.secondSpinLoader = true;
    this.crmService.requestToken().subscribe(res => { 
      this.token = res.access_token;
      this.crmService.addHistoryContact(id, form.value, this.token).subscribe(res => {
        console.log("Esto recibo de agregar historia de contacto: ", res);
        this.secondSpinLoader = false;
        this.modal.open(this.creactionSatisfactoria, { size: 'md', centered: true, scrollable: true, windowClass: 'modal__class' });
        setTimeout(() => {
          this.modal.dismissAll();
          this.variable = '';
        },1500);
      }, error => {
        this.errorManage(error, form, formDirective);
      });
    }, error => {
      this.errorManage(error, form, formDirective);
    });
  }

  public onSubmitRegister(form: any, formDirective: FormGroupDirective) {
    let product:string = '' ;
    this.secondSpinLoader = true;
    this.selectedItems.forEach(item=>{
    product = product+' '+'referencia: '+item.item_id+ '  descripcion: '+item.item_text+' //';
    });
    form.value.user_description = form.value.user_description+'/ PRODUCTOS SOLICITADOS '+product+'/ telefono: '+form.value.telefono;
    console.log("Este es el form: ", form.value);

     this.crmService.requestToken().subscribe(res => {
      this.token = res.access_token;
      console.log("este es el form que envìo: ", form.value);
      this.crmService.createNewCrmContact(form.value, this.token).subscribe(res => {
        console.log("Esta es la respuesta del servicio: ", res);


        // Acà puedo dejarlo para que reciba el id del cliente creado y después agrego la historia:
        
        this.secondSpinLoader = false;
        form.reset();
        formDirective.resetForm();
        this.modal.dismissAll();
        this.modal.open(this.creactionSatisfactoria, { size: 'md', centered: true, scrollable: true, windowClass: 'modal__class' });
        setTimeout(() => {
          this.modal.dismissAll();
          this.variable = '';
        },1500);

      }, error => {
        this.errorManage(error, form, formDirective);
      });
    }, error => {
        this.errorManage(error, form, formDirective);
    }); 

  }

  public onSubmitSearch(form, modal) {
    console.log("Esto es input: ", this.variable);

    if (this.variable.nit) {
      this.status = 'success';
      this.clientService.setCliente(this.variable);
      this.router.navigate(['home/dashboard']);
    } else {
      this.status = 'error';
      this.modal.open(modal, { size: 'md', centered: true, scrollable: true, windowClass: 'modal__class' }).result.then(result => {
        console.log("Esto es resulto: ", result);
      }, reason => { });
    }
  }
  public logout() {
    //console.log("Entramos a logout");
    localStorage.removeItem('token');
    localStorage.removeItem('identity');
    sessionStorage.removeItem('identity');
    sessionStorage.removeItem('cliente');
    sessionStorage.removeItem('items_recomendados');

    this.identity = null;
    this.token = null;

    // Redirección a la página principal.
    this.router.navigate(['intro']);

  }

  public data2;
  public dataVdeo:Video;
  public nombrev: String;
  
  getVideoActivo(){

    let data2: Video;
    return this.videoService
    .getVideoAct(1).pipe(map(r => { 
      data2 = r.video;
      this.videoAct = data2;
      console.log("Video a mostrar", this.videoAct.name_video)
     
      return this.videoAct;

    }));

    
  }

  serviceData ;

  ngOnInit() {
    this.getVideoActivo().subscribe( r => {
      //console.log('ngOnit after getUsers() ' + r);
      this.serviceData = r;
      //console.log("datos para url", this.serviceData)
      this.url_videos = this.url_videos+ this.serviceData?.name_video;
      console.log("URL CONSTRUIDA", this.url_videos+ this.serviceData.name_video)
    });

    console.log("Url", this.videoAct)
    this.getClients();
    //this.getContactsCrm();

  }
  async getClients() {
    this.clientService.getAllClientes().subscribe(res => {
      this.clientes_new = res.clients;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.nombre),
          map(name => name ? this._filter(name) : this.clientes_new.slice())
        );
    });
  }



  public getContactsCrm() {
    this.crmService.requestToken().subscribe(res => {
      this.token = res.access_token;
    });
    this.crmService.getContactsFromCrm(this.token).subscribe(res => {
      console.log("Respueta: ", res);
    }, error => {
      console.log("Ups, ha habido un error: ", error);
    });
  }





  displayFn(cliente: Clientes): string {
    return cliente && cliente.nombre ? cliente.nombre : '';
  }

  private _filter(name: string): Clientes[] {
    const filterValue = name.toLowerCase();
    return this.clientes_new.filter(cliente =>
      cliente.nombre &&
      cliente.nombre.toLowerCase().indexOf(filterValue) === 0);
  }

  public closeAllModals() {
    this.modal.dismissAll();
  }

  public errorManage(error: any, form: any, formDirective: any) {
    this.spinLoader = false;
    console.log("Ups, ha habido un error: ", error);
    this.modal.dismissAll();
    form.reset();
    formDirective.resetForm();
    this.modal.open(this.modalContent, this.options);
    setTimeout(() => {
      this.modal.dismissAll();
    }, 3000);
  }
  onItemSelect(item: any) {
    console.log(item);
    this.selectedItems = item;
  }


}
