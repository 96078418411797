<div class="page">
    <div class="row no-gutters h-100" style="background-color: #FBF6F2;">
        <div class="col-lg-9 col__9" [ngClass]="changeClass(is_inventory)">

            <nav class="navbar p-0 py-3 align-middle">
                <div class="row all-width">
                    <div class="col-12 col-md-9">
                        <a class="navbar-brand p-0 w-100 align-middle">
                            <img *ngIf="is_inventory==='true'" [routerLink]="['/home/productos-inventario']" routerDirection="backward" class="align-middle" src="assets/img/detalleProductoPage/back_arrow.svg" style="cursor: pointer;">
                            <img *ngIf="is_inventory==='false'" [routerLink]="['/home/dashboard']" routerDirection="backward" class="align-middle" src="assets/img/detalleProductoPage/back_arrow.svg" style="cursor: pointer;">
                            <span class="align-middle ml-3 w-100" style="color: #43425D;
                                    font-family: 'Source Sans Pro', sans-serif;
                                    font-weight: 400; font-size: 1.2rem;">Detalle del Producto Recomendado</span>

                            <div>
                                <h1 style="display: block; margin: 0;"></h1>
                                <h1 style="display: block; margin: 0;"></h1>
                            </div>
                        </a>
                    </div>
                    <div class="col-12 col-md-3 center-vertical d-flex justify-content-center">
                        <button id="exportButton" (click)="downloadPdf(this.id)" class="btn btn-lg btn-secondary  clearfix btn-sm"><span class="fa fa-file-pdf-o"></span> Exportar a PDF</button>
                    </div>
                    <div class="col-12 col-md-3 center-vertical d-flex justify-content-center">
                        <button id="exportButton2" (click)="downloadPdf_movil(this.id)" class="btn btn-lg btn-secondary  clearfix btn-sm"><span class="fa fa-file-pdf-o"></span> Exportar a PDF</button>
                    </div>

                </div>


            </nav>

            <div class="row m-0" id="first__row">
                <div class="col-sm-6 p-0 flex__xs">
                    <div class="pr-1 border-0 order-xs-3 " id="image__container">
                        <div  id="image__container2">
                        <!-- <img class="w-100 h-100" src="assets/img/detalleProductoPage/Base.png"> -->
                        <img [src]="url_image" class="w-100 h-100" onError="this.src='assets/img/detalleCompraPage/box.svg'">
                        </div>
                        <i id="elipses" class="fas fa-ellipsis-v"></i>
                    </div>
                    <h4 class="mt-3 order-xs-1" id="description__producto">
                        Referencia item : {{ this.id }}
                    </h4>
                    <h4 class="mt-3 order-xs-2" id="description__producto">
                        Descripción : {{ this.descripcion }}
                    </h4>
                    <div class="container-img">
                        <img  style="width: auto;" src="assets/img/dashboardPage/logo_header.svg">
                    </div>
                    
                </div>
                <div class="col-sm-6" id="column__5">
                    <div id="container__description">
                        <div class="card h-105" style="border-radius: 0;">
                            <div class="card-body pt-0">
                                <h5 class="card-title mt-3">Especificaciones</h5>
                                <div class="row no-gutters" style="height: 90%; padding-bottom: 5%; padding-bottom: 2%;">
                                    <div class="col-7 border-right">
                                        <!-- <h3 class="title_description">CLIENTE</h3>
                                        <h3 class="description">{{ nombreCliente }}</h3> -->

                                        <h3 class="title_description">NÚMERO DE LINEA</h3>
                                        <h3 class="description mt-1">{{ numLinea }}</h3>

                                        <h3 class="title_description mt-2">LINEA</h3>
                                        <h3 class="description mt-1">{{ linea }}</h3>

                                        <h3 class="title_description mt-2">NÚMERO DE GRUPO</h3>
                                        <h3 class="description mt-1">{{ numGrupo }}</h3>

                                        <h3 class="title_description mt-2">GRUPO</h3>
                                        <h3 class="description mt-1">{{ grupo }}</h3>

                                        <h3 class="title_description mt-2">MARCA</h3>
                                        <h3 class="description mt-1">{{ marca }}</h3>

                                        <h3 class="title_description mt-2">PRECIO EN DOLARES</h3>
                                        <h3 class="description mt-1"> US$ {{ precio_dolar }}</h3>
                                    </div>
                                    <div class="col-5 pl-2">
                                        <h3 class="title_description">CANTIDAD</h3>
                                        <h3 class="description mt-1">{{ cantidad }}</h3>

                                        <h3 class="title_description mt-2">REFERENCIA</h3>
                                        <h3 class="description mt-1">{{ id }}</h3>

                                        <h3 class="title_description mt-2">ELEMENTO</h3>
                                        <h3 class="description mt-1">{{ elemento }}</h3>

                                        <h3 class="title_description mt-2">REF PROVEEDOR</h3>
                                        <h3 class="description mt-1">{{ refProveedor }}</h3>

                                        <h3 class="title_description mt-2">SERIAL</h3>
                                        <h3 class="description mt-1">{{ serial }}</h3>

                                        <h3 class="title_description mt-2">CANTIDAD</h3>
                                        <h3 class="description mt-1">{{ cantidad }}</h3>


                                    </div>
                                </div>
                            </div>
                            <div class="card-footer justify-content-center text-center">
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-3 card w-100" id="card__price">
                        <div class="text-center my-auto" style="font-family: 'Source Sans Pro', sans-serif;
                            font-weight: 600;">
                            <h3 style="color: #191918; font-size: 1.5rem; margin: 0;">Precio del artículo</h3>
                            <h3 class="py-1" style="font-size: 2.5rem; color: #191918; font-weight: 400;">${{ precio | number: '1.0-0' }}</h3>
                            <!-- <h3 style="color: #354052; font-size: 1.6rem; font-weight: 400; text-decoration: line-through; opacity: 0.5; margin: 0">$4,594</h3> -->
                            <h5  style="color: #191918; font-size: 0.6rem; margin: 12;"> TRM: ${{ trm_ob| number: '1.0-2' }} - {{trm_date| date: 'dd/MM/yyyy'}}</h5>
                        </div>
                        <!-- <div class="row no-gutters h-100 align-middle">

                            <div class="col-6 text-center justify-content-center my-auto" style="font-family: 'Rubik', sans-serif;
                                font-weight: 400;">
                                <button style="display: block; width: 80%; margin: auto;  font-size: .7rem; height: 40px;" type="button" class="btn btn-outline-secondary">Agregar a canasta</button>
                                <button style="display: block;height: 40px; width: 80%; margin-top: 7%; margin-left: auto; margin-right: auto; font-size: .8rem;" type="button" class="btn btn-dark">Comprar</button>

                            </div>

                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="is_inventory==='false'" class="col-lg-3 col__3">
            <div class="w-100 px-3" id="third__column">
                <nav class="px-2 navbar navbar-expand-lg" style="height: 60px;">
                    <a class="navbar-brand recomendados__tag">Recomendados</a>
                    <div class="collapse navbar-collapse w-100" id="navbarNavDropdown">
                        <!-- <ul class="navbar-nav w-100 text-right">
                            <div class="dropdown w-100">
                                <button type="button" class="dropdown__settings btn dropdown-toggle" data-toggle="dropdown">
                                    Todos
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item" href="#">Link 1</a>
                                    <a class="dropdown-item" href="#">Link 2</a>
                                    <a class="dropdown-item" href="#">Link 3</a>
                                </div>
                            </div>
                        </ul> -->
                    </div>
                </nav>

                <div class="table-responsive" id="list__recomendados">
                    <table class="table table-borderless table-hover" style="border: none;">
                        <tbody>
                            <tr *ngFor="let item of items_recomendados" class="d-flex align-items-start item__recomendado mt-2" (click)="changeData(item.referencia)">
                                <th>
                                    <!-- <img style="width: 20%; border-radius: 10px;" src="assets/img/dashboardPage/tshuc.png"> -->
                                    <div class="ml-0 my-auto" style="display: block;">
                                        <h6 [routerLink]="['/home/detalle-producto', item.referencia,false]" routerDirection="forward" class="m-0 etiqueta__titulo_item align-middle">
                                            {{ item.descripcion }}</h6>
                                        <div class="favorite__container">
                                            <div class="favorite__container_first">
                                                <h6 class="mt-2 etiqueta__description_item">{{ item.referencia }}
                                                </h6>
                                                <h6 class="m-0 mt-1 align-middle" style="color: #3BD66A; font-size: .8rem;">
                                                    ${{ item.precio | number: '1.0-0' }}</h6>
                                            </div>
                                            <!-- <div class="favorite__container_second" (click)="toggleFavorite(item)">
                                                <i *ngIf="item.favorite == 0" class="far fa-heart"></i>
                                                <i *ngIf="item.favorite == 1" class="fas fa-heart"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                    <!-- <div class="my-auto mx-auto" style="display: block;">

                                    </div> -->
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="py-2 text-center" style="height: 60px;">
                    <div class="btn w-50 btn__vermas" [routerLink]="['/home/lista-productos']" routerDirection="forward">
                        <span class="" style="font-weight: bold;">Ver Más</span>
                        <i class="fa fa-arrow-right ml-2"></i>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-climbing-dot">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>