<!-- <div class="row">
    <div class="col-12 col-md-8">
        <form class=" col-12 col-md-5 input-group rounded margin-filter">
            <input [(ngModel)]="search_input" type="search" [formControl]="myControl" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
            <button class="input-group-text border-0" id="search-addon" (click)="getProducts()">
                        <i class="fas fa-search"></i>
            </button>
        </form>
    </div>
    <div class="col-6">
        <ng-multiselect-dropdown [placeholder]="'Seleccionar producto'" [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" (onFilterChange)="onFilterChange($event)">
        </ng-multiselect-dropdown>

    </div>


</div> -->

<!-- <form #registerForm="ngForm" (ngSubmit)="onSubmitRegister(registerForm)">
    <div class="form-group">
        <label for="exampleFormControlInput1">Nombre(s)</label>
        <input formControlName="first_name" type="email" class="form-control" id="exampleFormControlInput1" placeholder="Nombre(s)">
    </div>
    <div class="form-group">
        <label for="exampleFormControlInput2">Apellido(s)</label>
        <input formControlName="last_name" type="email" class="form-control" id="exampleFormControlInput2" placeholder="Apellido(s)">
    </div>
    <div class="form-group">
        <label for="exampleFormControlInput3">Correo electrónico</label>
        <input formControlName="email" type="email" class="form-control" id="exampleFormControlInput3" placeholder="nombre@example.com">
    </div>
    <div class="form-group">
        <label for="exampleFormControlInput3">Correo electrónico</label>
        <div class="form-inline my-2 my-lg-0">
            <input [(ngModel)]="search_input" type="email" formControlName="email" type="email" class="form-control" />
            <button class="input-group-text " id="search-addon" (click)="getProducts()">
                <i class="fas fa-search"></i>
            </button>
        </div>
        <ng-multiselect-dropdown [placeholder]="'Seleccionar producto'" [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
    </div>
    <div class="form-group">
        <label for="exampleFormControlTextarea1">Breve descripción del cliente</label>
        <textarea formControlName="user_description" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
    </div>
    <div class="form-group">
        <label for="exampleFormControlInput4">Título de Reporte</label>
        <input formControlName="tag_description" type="email" class="form-control" id="exampleFormControlInput4" placeholder="Breve título de la observación">
    </div>
    <div class="form-group">
        <label for="exampleFormControlTextarea2">Observación</label>
        <textarea formControlName="event_description" class="form-control" id="exampleFormControlTextarea2" rows="3"></textarea>
    </div>

</form> -->
<!-- <div class="form-group">
    <label for="exampleFormControlInput3">Correo electrónico</label>
    <div class="row">
        <div class="form-inline my-2 my-lg-0 col-12 col-md-4 d-inline-flex">
            <input [(ngModel)]="search_input" ng-keypress="getProducts()" type="email" type="email" class="form-control" />
            <a class="input-group-text " id="search-addon" (click)="getProducts()">
                <i class="fas fa-search"></i>
            </a>
        </div>
        <div class="col-12 col-md-6">
            <ng-multiselect-dropdown [placeholder]="'Seleccionar producto'" [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
            </ng-multiselect-dropdown>
        </div>
    </div>


</div> -->
<div class="row">

    <div class="col-12 col-md-4">
        <div class="input-group">
            <input type="text" [(ngModel)]="search_input" class="form-control" placeholder="Buscar producto">
            <a class="input-group-text " id="search-addon" (click)="getProducts()">
                <i class="fas fa-search"></i>
            </a>



        </div>

    </div>
    <div class="col-12 col-md-6">
        <ng-multiselect-dropdown [placeholder]="'Seleccionar producto'" [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
    </div>
</div>