import { Clientes } from '../../models/clientes';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { global } from '../global';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  public url: string;
  public cliente_Buscado: any;
  public mySubject: Subject<Clientes> = new Subject<Clientes>();
  public mySubject2: Subject<Clientes> = new Subject<Clientes>();
  public itemsRecomendados: Subject<any> = new Subject<any>();
  public changeClientLists: Subject<boolean> = new Subject<boolean>();
  public myData: Clientes;
  public myData2: Clientes;

  constructor(
    private http: HttpClient 
  ) {
    this.url = global.url;
  }

  public getAllClientes(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    
    return this.http.get(this.url + 'cliente', { headers: headers });
  }

  public getCliente() {
    let cliente = JSON.parse(sessionStorage.getItem('cliente'));

    if (cliente && cliente != 'undefined') {
      this.cliente_Buscado = cliente;
    } else {
      this.cliente_Buscado = null;
    }

    return this.cliente_Buscado;
  }

  public setCliente(cliente: Clientes) {
    sessionStorage.setItem('cliente', JSON.stringify(cliente));
    this.myData = cliente;
    this.mySubject.next(this.myData);
  }

  public setCliente2(cliente: Clientes) {
    sessionStorage.setItem('cliente', JSON.stringify(cliente));
    this.myData2 = cliente;
    this.mySubject2.next(this.myData2);
  }

  public flagToChangeData(flag: boolean) {
    this.changeClientLists.next(flag);
  }

  public changeRecomendedItems(id: any) {
    this.itemsRecomendados.next(id);
  }

  public getInfoCliente(idCliente: number): Observable<any>{
    let id = JSON.stringify(idCliente);

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    console.log("Info del cliente:"+this.http.get(this.url + 'clientes/getInfo/' + id, { headers: headers }));

    return this.http.get(this.url + 'clientes/getInfo/' + id, { headers: headers });
  }

  // public setCliente(cliente: Clientes) {
  //   sessionStorage.setItem('cliente', JSON.stringify(cliente));
  // }
}
