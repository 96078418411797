<div class="box font-small pt-sm-1 pt-lg-2">
    <div class="row mt-5 w-100 h-100 general__row">
        <div class="col-lg-9 w-100 h-100 general__col">
            <!-- EMPIEZA EL FORMULARIO -->
            <form #loadDataForm="ngForm" (ngSubmit)="onSubmitData(loadDataForm)">

                <h3 id="title">Subida de siigo.</h3>
                <angular-file-uploader class="file__uploader" [resetUpload]=" resetVar " [config]="afuConfig" (click)="onLoadSpinner($event)" (ApiResponse)="dataUpload($event)">
                </angular-file-uploader>
                <!-- <div class="custom-file mt-3 mb-1 ">
                    <input type="file " class="custom-file-input h-100 " name="data " #data="ngModel " [(ngModel)]="file ">
                    <label class="custom-file-label h-100 m-0 " for="data ">{{ label }}</label>
                </div> -->

                <div *ngIf="status=='error' " class="info__message mb-2 alert alert-danger d-blok ">
                    Error al subir archivo. <span *ngIf="message ">{{message}}</span>
                </div>

                <div *ngIf="status=='success' " class=" info__message mb-2 alert alert-success d-blok "><span *ngIf="message ">{{message}}</span>
                </div>

                <!-- <div class="d-flex mt-3 justify-content-between bd-highlight mb-1 my-2 ">
                    <button type="submit " class="btn w-100 mx-auto font-button " [disabled]="loadDataForm.invalid ">Subir
                        archivo</button>
                </div> -->
            </form>
            <hr>
            <form #loadDataForm2="ngForm" (ngSubmit)="onSubmitDataInventory(loadDataForm2)">

                <h3 id="title" class="mt-3">Subida de productos del inventario.</h3>
                <angular-file-uploader class="file__uploader" [resetUpload]="resetVarInventory" [config]="afuConfigInventory" (click)="onLoadSpinner($event)" (ApiResponse)="dataUploadInventory($event)">
                </angular-file-uploader>
                <!-- <div class="custom-file mt-3 mb-1 ">
                    <input type="file " class="custom-file-input h-100 " name="data " #data="ngModel " [(ngModel)]="file ">
                    <label class="custom-file-label h-100 m-0 " for="data ">{{ label }}</label>
                </div> -->

                <div *ngIf="statusInventory=='error' " class="info__message mb-2 alert alert-danger d-blok ">
                    Error al subir archivo. <span *ngIf="message ">{{message}}</span>
                </div>

                <div *ngIf="statusInventory=='success' " class=" info__message mb-2 alert alert-success d-blok "><span *ngIf="messageInventory">{{messageInventory}}</span>
                </div>

                <!-- <div class="d-flex mt-3 justify-content-between bd-highlight mb-1 my-2 ">
                    <button type="submit " class="btn w-100 mx-auto font-button " [disabled]="loadDataForm.invalid ">Subir
                        archivo</button>
                </div> -->
            </form>
            <hr>
            <form #loadDataImages="ngForm" (ngSubmit)="onSubmitDataImages(loadDataImages)">

                <h3 id="title" class="mt-3">Subida de imagenes.</h3>
                <angular-file-uploader class="file__uploader" [resetUpload]="resetVarInventory" [config]="afuConfigImages" (click)="onLoadSpinner($event)" (ApiResponse)="dataUploadImages($event)">
                </angular-file-uploader>
                <div *ngIf="statusInventory=='error' " class="info__message mb-2 alert alert-danger d-blok ">
                    Error al subir archivo. <span *ngIf="message ">{{message}}</span>
                </div>

                <div *ngIf="statusInventory=='success' " class=" info__message mb-2 alert alert-success d-blok "><span *ngIf="messageInventory">{{messageInventory}}</span>
                </div>
            </form>
            <hr>
            <div class="d-flex mt-4 justify-content-between bd-highlight " style="padding-right: .6rem !important; margin-bottom: 20% !important; margin-top: 10% !important;">
                <button (click)="goToDashboard() " class="btn mx-auto font-button">Regresar a Dashboard</button>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8) " size="medium " color="#fff " type="ball-climbing-dot ">
    <p style="font-size: 20px; color: white ">Subiendo Archivo...</p>
</ngx-spinner>