import { ClientesService } from 'src/app/services/Clientes/clientes.service';
import { global } from './../../services/global';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
@Component({
  selector: 'app-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.scss']
})
export class DataImportComponent implements OnInit {

  public status: string;
  public statusInventory: string;
  public message: string;
  public messageInventory: string;
  public file: any;
  public fileInventory: any;
  public label: string = 'Selecciona Archivo';
  public labelInventory: string = 'Selecciona Archivo';
  public resetVar: boolean;
  public resetVarInventory: boolean;
  public afuConfig = {
    multiple: false,
    formatsAllowed: ".xlsx,.xls,.csv",
    maxSize: "50",
    uploadAPI: {
      url: global.url + 'import',
      method: "POST",
      headers: {
        //  "Authorization" : `Bearer ${token}`
      }
    },
    //theme: "attachPin",
    hideProgressBar: false, 
    hideResetBtn: true,
    hideSelectBtn: false,
    fileNameIndex: true,
    replaceTexts: {
      selectFileBtn: 'Seleccionar archivos',
      resetBtn: 'Reset',
      uploadBtn: 'Subir Archivo',
      dragNDropBox: 'attachPin',
      attachPinBtn: 'Subir archivo    ',
      afterUploadMsg_success: 'Carga de datos satisfactoria !',
      afterUploadMsg_error: 'Ups, Algo ha salido mal !',
      sizeLimit: 'Límite de memoria'
    }
  };
  public afuConfigInventory = {
    multiple: false,
    formatsAllowed: ".xlsx,.xls,.csv",
    maxSize: "50",
    uploadAPI: {
      url: global.url + 'importInventory',
      method: "POST",
      headers: {
        //  "Authorization" : `Bearer ${token}`
      }
    },
    //theme: "attachPin",
    hideProgressBar: false, 
    hideResetBtn: true,
    hideSelectBtn: false,
    fileNameIndex: true,
    replaceTexts: {
      selectFileBtn: 'Seleccionar archivos',
      resetBtn: 'Reset',
      uploadBtn: 'Subir Archivo',
      dragNDropBox: 'attachPin',
      attachPinBtn: 'Subir archivo    ',
      afterUploadMsg_success: 'Carga de datos satisfactoria !',
      afterUploadMsg_error: 'Ups, Algo ha salido mal !',
      sizeLimit: 'Límite de memoria'
    }
  };
  public afuConfigImages = {
    multiple: true,
    formatsAllowed: ".jpeg,.jpg,.png",
    maxSize: "500000",
    uploadAPI: {
      url: global.url + 'uploadMultipleImages',
      method: "POST",
      headers: {
        //  "Authorization" : `Bearer ${token}`
      }
    },
    //theme: "attachPin",
    hideProgressBar: false, 
    hideResetBtn: true,
    hideSelectBtn: false,
    fileNameIndex: true,
    replaceTexts: {
      selectFileBtn: 'Seleccionar archivos',
      resetBtn: 'Reset',
      uploadBtn: 'Subir Imagenes',
      dragNDropBox: 'attachPin',
      attachPinBtn: 'Subir imagenes    ',
      afterUploadMsg_success: 'Carga de datos satisfactoria !',
      afterUploadMsg_error: 'Ups, Algo ha salido mal !',
      sizeLimit: 'Límite de memoria'
    }
  };

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private clientService: ClientesService
  ) {
    this.status = '';
    this.statusInventory = '';
    this.message = '';
    this.messageInventory = '';
    this.resetVar = false;
    this.resetVarInventory = false;
  }

  ngOnInit(): void {
  }

  public onSubmitData(form: any) {
    this.label = this.file;
    //console.log(this.file);
  }
  public onSubmitDataInventory(form: any) {
    this.labelInventory = this.fileInventory;
    //console.log(this.file);
  }
  public onSubmitDataImages(form: any) {
    //console.log(this.file);
  }

  public onLoadSpinner(data: any) {
    //this.spinner.show();
    //console.log("Entramos a donde quiero" ,data);
  }

  public goToDashboard() {
    this.router.navigate(['home/dashboard']);
  }

  public dataUpload(datos: any) {
    if (datos.body.code = 200) {
      // Acá va todo bien
      this.status = datos.body.status;
      this.message = datos.body.message;
      this.resetVar = true;
      this.clientService.flagToChangeData(true);
      this.spinner.hide();
      this.router.navigate(['search-page']).then(() => {
        sessionStorage.removeItem('cliente');
      }, error => {
          console.log('Ups, ha habido un error: ', error);
      });
    } else {
      this.status = 'error';
      this.spinner.hide();
    }
    //console.log(datos);
    
  }
  public dataUploadInventory(datos: any) {
    console.log("Esta es la rta de inventory: ", datos);
    if (datos.body.code = 200) {
      // Acá va todo bien
      this.statusInventory = datos.body.status;
      this.messageInventory = datos.body.message;
      this.resetVarInventory = true;
      this.clientService.flagToChangeData(true);
      this.router.navigate(['search-page']).then(() => {
        sessionStorage.removeItem('cliente');
      }, error => {
          console.log('Ups, ha habido un error: ', error);
      });
      this.spinner.hide();
    } else {
      this.statusInventory = 'error';
      this.spinner.hide();
    }
    //console.log(datos);
    
  }
  public dataUploadImages(datos: any) {
    console.log("Esta es la rta de images: ", datos);
    // if (datos.body.code = 200) {
    //   // Acá va todo bien
    //   this.statusInventory = datos.body.status;
    //   this.messageInventory = datos.body.message;
    //   this.resetVarInventory = true;
    //   this.clientService.flagToChangeData(true);
    //   this.router.navigate(['search-page']).then(() => {
    //     sessionStorage.removeItem('cliente');
    //   }, error => {
    //       console.log('Ups, ha habido un error: ', error);
    //   });
    //   this.spinner.hide();
    // } else {
    //   this.statusInventory = 'error';
    //   this.spinner.hide();
    // }
    //console.log(datos);
    
  }

}
