import { UserService } from './../../services/User/user.service';
import { User } from './../../models/user';
import { Component, OnInit } from '@angular/core';
import { global } from '../../services/global';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
  providers: [UserService]
})
export class IntroComponent implements OnInit {

  public bandera: boolean;
  public user: User;
  public url: string;
  public status: string;
  public message: string;
  public token;
  public identity;
  public checkBox: boolean;

  constructor(
    private _router: Router,
    private _userService: UserService,
    private spinner: NgxSpinnerService
  ) {
    this.bandera = true;
    this.user = new User(1, '', '', 1, 0, '', '', '');
    this.checkBox = false;
  }


  ngOnInit(): void {

  }

  public onChangeToRegisterView() {
    this.bandera = false;
  }



  public onSubmitLogin(form) {
    console.log("Usuario: ", this.user);
    console.log("Checkbox: ", this.checkBox);
    this.spinner.show();
    this._userService.signup(this.user).subscribe(
      response => {
        // TOKEN
        if (response.status != 'error') {
          this.status = 'success';
          this.token = response;

          // OBJETO USUARIO IDENTIFICADO.
          this._userService.signup(this.user, true).subscribe(
            response => {
              this.identity = response;

              //console.log("Token: ", this.token);
              //console.log("Identity: ", this.identity);

              // PERSISTIR DATOS DEL USUARIO IDENTIFICADO.
              console.log("Token: ", this.token);
              console.log("Identity: ", this.identity);

              if (this.checkBox) {
                localStorage.setItem('token', this.token);
                localStorage.setItem('identity', JSON.stringify(this.identity));
                this.spinner.hide();
                this._router.navigate(['search-page']);
              } else {
                sessionStorage.setItem('token', this.token);
                sessionStorage.setItem('identity', JSON.stringify(this.identity));
                this.spinner.hide();
                this._router.navigate(['search-page']);
              }
            }, error => {
              this.status = 'error';
              this.spinner.hide();
              console.log("Ups ha habido un error: ", error);
            });
        } else {
          this.status = 'error';
          this.spinner.hide();
          this.message = response.message;
          console.log("Ups ha habido un error: ", response.message);
        }

      }, error => {
        this.spinner.hide();
        this.status = 'error';
        console.log("Ups ha habido un error: ", error);
      });
  }

  public onChangeToLoginView() {
    this.bandera = true;
  }

  public onSubmitRegister(form) {
    this._userService.register(this.user).subscribe(
      response => {
        if (response.status == 'success') {
          this.status = response.status;
          this.bandera = true;
          form.reset();
        } else {
          this.status = response.status;
        }
      }, error => {
        if (error.error.error.email[0]) {
          this.status = 'error';
          //console.log("Este es status: ", this.status);
          this.message = "Ese correo electrónico ya está registrado";
          //console.log("Ese correo electrónico ya está registrado");
        }
        this.status = 'error';
        this.message = "No se ha podido crear el usuario.";
        //console.log("Ups, ha habido un error: ", error.error.error);
      });
  }

}
