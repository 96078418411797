import { UserRegisterComponent } from './pages/user-register/user-register.component';
import { HomeComponent } from './pages/home/home.component';
import { ListaProductosComponent } from './pages/lista-productos/lista-productos.component';
import { DetalleProductoComponent } from './pages/detalle-producto/detalle-producto.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntroComponent } from './pages/intro/intro.component';
import { DetalleCompraComponent } from './pages/detalle-compra/detalle-compra.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { DataImportComponent } from './pages/data-import/data-import.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { AuthGuardGuard } from './guards/authGuard/auth-guard.guard';
import { IntroShowedGuard } from './guards/introShowed/intro-showed.guard';
import { IsClientGuard } from './guards/clientExist/is-client.guard';
import { InventarioComponent } from './pages/inventario/inventario.component';
import { MultiComponent } from './pages/multi/multi.component';
import { TrmComponent } from './pages/trm/trm.component';
import { UploadVideoComponent } from './pages/upload-video/upload-video.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full',
  },
  {
    path: 'intro',
    component: IntroComponent,
    canActivate: [IntroShowedGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [
      AuthGuardGuard,
      IsClientGuard
    ],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [IsClientGuard]
      },
      {
        path: 'detalle-producto/:id/:is_inventory',
        component: DetalleProductoComponent,
        canActivate: [IsClientGuard]
      },
      {
        path: 'lista-productos',
        component: ListaProductosComponent,
        canActivate: [IsClientGuard]
      },
      {
        path: 'detalle-compra/:id',
        component: DetalleCompraComponent,
        canActivate: [IsClientGuard]
      },
      {
        path: 'user-register',
        component: UserRegisterComponent,
        canActivate: [IsClientGuard]
      },
      {
        path: 'data-import',
        component: DataImportComponent,
        canActivate: [IsClientGuard]
      },
      {
        path: 'user-list',
        component: UserListComponent,
        canActivate: [IsClientGuard]
      },
      {
        path: 'productos-inventario',
        component: InventarioComponent,
        canActivate: [IsClientGuard]
      },
      {
        path: 'multi-demo',
        component: MultiComponent,
        canActivate: [IsClientGuard]
      },
      {
        path: 'trm',
        component: TrmComponent,
        canActivate: [AuthGuardGuard]
      },
      {
        path: 'video',
        component: UploadVideoComponent,
        canActivate: [AuthGuardGuard]
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      }
    ]
  },
  {
    path: 'search-page',
    component: SearchPageComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'logout',
    component: IntroComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true}),
    //RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
