import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { global } from '../global';

@Injectable({
  providedIn: 'root'
})
export class DatesService {

  public url: string;
  constructor(
    private http: HttpClient
  ) {
    this.url = global.url;
   }
  public getDates(): Observable<any> {
    
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(this.url + 'dates', { headers: headers });

  }
  public setSpecialDate(data: any): Observable<any>{
    let json = JSON.stringify(data);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(this.url + 'specialDate/create', params, { headers: headers });
  }
  public getSpecialDate(idCliente: number): Observable<any>{
    let id = JSON.stringify(idCliente);

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.get(this.url + 'getSpecialDate/' + id, { headers: headers });
  }
  public deleteSpecialDate(idDate: number): Observable<any>{
    let id = JSON.stringify(idDate);

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.get(this.url + 'specialDate/delete/' + id, { headers: headers });
  }
}
