import { Clientes } from './../../models/clientes';
import { Component, OnInit, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/services/Dashboard/dashboard.service';
import { Ventas} from 'src/app/models/ventas';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientesService } from 'src/app/services/Clientes/clientes.service';
import { ignoreElements, map, startWith, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
// import * as am4core from '@amcharts/amcharts4/core';
// import * as am4charts from '@amcharts/amcharts4/charts';
// import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ChartComponent, ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels, ApexTitleSubtitle, ApexStroke, ApexGrid, ApexAnnotations, ApexResponsive,ApexNoData, ApexPlotOptions, ApexOptions, ApexYAxis
} from "ng-apexcharts";
import { DatesService } from 'src/app/services/Dates/dates.service';
import { DatePipe, getLocaleNumberFormat } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import { TrmService } from 'src/app/services/Trm/trm.service';
import { Trm } from 'src/app/models/trm';
import { Router } from '@angular/router';



// am4core.useTheme(am4themes_animated);
export type ChartOptions = {
  series: ApexAxisChartSeries;
  options: ApexOptions;
  annotations: ApexAnnotations;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  labels: string[];
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  responsive: ApexResponsive[];
  noData: ApexNoData;
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
  

export class DashboardComponent implements OnInit, OnDestroy {


  @ViewChild("chart", { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public client: Clientes;
  public comprasAno: number;
  public totalCompras: number;
  public ventas: Array<Ventas>;
  public items_recomendados: Array<Ventas[]>;

  public ventasFilt = [];
  dataSource: MatTableDataSource<Ventas>;
  public idsValue:string;

  public trm_data:Trm[];
  public mesage: string;
  public trm_ob: number;
  public trm_date: string;

  public datarelaship = [];
  public id_relaship:number;
  
  // public chart_2: am4charts.XYChart;
  // public chart_3;

  public xAxis;
  public yAxis;
  public clientes_new: Clientes[];
  public filteredOptions: Observable<Clientes[]>;
  public variable;
  public relationshipTags: Array<string>;
  public itemsDates = [];
  public datesList =[];
  public birthday: string;
  public fecha = '';
  public type_fecha:number;
  public id_registro;
  public title_registro;
  public fecha_registro;
  public newForm = this.fb.group({
    clientName: ['', Validators.required],
    selected: ['', Validators.required],
    dataOtro:['']
    
    
  });

  //Variables para el modal que permite eliminar una relacion
  public id_relation_registro;
  public cliente_registro;
  public relacion_registro;
  
  public spinLoader: boolean = false;
  public spinLoaderDelete: boolean = false;
  public unsubscribe$: Subject<void>;
  public clientesRelacionados: any[] = [];
  public isClickDate:boolean = false;
  public isDateInput:boolean= false;
  public isDateTypeInput:boolean= false;
  constructor(
    private zone: NgZone,
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    private clienteService: ClientesService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private datesService:DatesService,
    private modal: NgbModal,
    private TrmService: TrmService,
    private router: Router,
  ) {
    this.variable = '';
    this.birthday = '';
    this.chartOptions = {
      series: [],
      chart: { type: "line" },
      annotations: {},
      dataLabels: { enabled: false },
      stroke: { curve: "smooth" },
      grid: {},
      title: { text: "", align: "left" },
      labels: [''],
      xaxis: {},
      yaxis: {},
      responsive: [{}],
      noData: {
        text: "Loading.."
      }
    };
    this.comprasAno = 0;
    this.totalCompras = 0;
    


  }

  ngOnInit() {
    this.clienteService.mySubject.subscribe(res => {
      this.client = res;
      this.birthday = '';
      this.clientesRelacionados = [];
      this.spinner.show();
      console.log("Este es el cliente: ", res);
      this.getClientData();
      this.getClientsRelation();

    });
    this.spinner.show();
    this.client = this.clienteService.getCliente();
    console.log("Este es el cliente2: ", this.client);
    this.getClientData();
    this.getClients();
    this.getClientsRelation();
    
    
  }

  private getDatesSpecial(){
    this.datesService.getDates().subscribe(res=>{
      this.datesList = res.fechas;
      console.log(this.datesList);
    }, error => {
      this.spinner.hide();
      console.log("Ups, ha habido un error: ", error);
    })
  }
  private getClientData() {
    this.dashboardService.getClientData(this.client.nit).subscribe(res => {
      this.comprasAno = res.totalComprasCliente;
      this.totalCompras = res.totalCompras;
      this.ventas = res.ventas;
      this.items_recomendados = res.items_recomendados;
      this.relationshipTags = res.relationship_tags;
      this.birthday = res.birthday;
      this.itemsDates = res.specialDates;
      //console.log(this.itemsDates);
      this.spinner.hide();
      this.generateFirstChart();

      console.log("Compras del cliente: ", this.ventas);
      // console.log("Estas son las relationship Tags: ", this.relationshipTags);

     
      this.dataSource = new MatTableDataSource(this.ventas);
      this.dataTrm()
      //this.mostrarCompra();
      //console.log(this.obtieneDatos());
      // this.generateSecondChart();
      sessionStorage.setItem('items_recomendados', JSON.stringify(this.items_recomendados));
      console.log('Recomendacione: ',this.items_recomendados);
    }, error => {
      this.spinner.hide();
      console.log("Ups, ha habido un error: ", error);
    })
  }

  public dataTrm(){
    this.TrmService.getTrm().subscribe(res =>{
      this.trm_data = res.trmf;
      this.trm_ob = this.trm_data['trm'];
      this.trm_date = this.trm_data['created_at'];
      
      //console.log('Trm btenida', this.trm_data['trm']);
      //this.mesage = res.msg;
      //console.log(this.mesage);
    })

  }


  //Valida que se haya seleccionado la opcion otro y habilita la caja de texto
  //tambien valida que la caja de texto no este vacia
  indOtro: Boolean = undefined;

  get(data) {
    console.log('Datos', data)
   
    if (data.relationship == 'Otro') {
      this.indOtro = true;
      console.log(data);
      this.newForm.get('dataOtro')?.setValidators([Validators.required]); //Habilita que el campo sea obligatorio
      this.newForm.get('dataOtro')?.updateValueAndValidity();
      
    } else {
      this.indOtro = false;
      this.newForm.controls['dataOtro'].clearValidators(); //Deshabilita el campo obligatorio
      this.newForm.get('dataOtro')?.updateValueAndValidity();      
    }
  }


  //Recorriendo el array de 


  toggleFavorite(item: any) {
    let data = {
      cliente: this.client.id,
      ref_product: item.id
    };
    this.dashboardService.setFavoriteItem(data).subscribe(res => {
      this.items_recomendados = res.new_items;
      //console.log('Recomendacione: ',this.items_recomendados);
      sessionStorage.setItem('items_recomendados', JSON.stringify(this.items_recomendados));
    }, error => {
        console.log('Ups, ha habido un error: ', error); 
    });
  }

  async getClients() {
    this.unsubscribe$ = new Subject<void>();
    this.clienteService.getAllClientes().subscribe(res => {
      this.clientes_new = res.clients;
      this.filteredOptions = this.newForm.controls.clientName.valueChanges
        .pipe(
          startWith(''), takeUntil(this.unsubscribe$),
          map(value => typeof value === 'string' ? value : ( value === null ? '' : value.nombre)),
          map(name => name ? this._filter(name) : this.clientes_new.slice())
        );
    });

  }


  public getClientsRelation() {
    // console.log("Este es el cliente: ", this.client);
    let request = {
      id: this.client.id
    };
    this.dashboardService.getRelatedCustomers(request).subscribe(res => {
      this.clientesRelacionados = res.clientes_relacionados;
      console.log('Clientes relacionados ', res);
    }, error => {
        console.log("Ups, ha habido un error: ", error); 
    });
  }

  displayFn(cliente: Clientes): string {
    return cliente && cliente.nombre ? cliente.nombre : '';
  }

  private _filter(name: string): Clientes[] {
    const filterValue = name.toLowerCase();
    return this.clientes_new.filter(cliente =>
      cliente.nombre &&
      cliente.nombre.toLowerCase().indexOf(filterValue) === 0);
  }

  selectedClient?: any
  

  public onSelectClient(item: any){
    let id_cliente;
    let clienterel;
    this.selectedClient = item;
    console.log('datos cliente seleccionado', this.selectedClient);
    console.log('identificacion', this.selectedClient['cliente_2'])
    id_cliente = this.selectedClient['cliente_2'];

    this.clienteService.getInfoCliente(id_cliente).subscribe(res =>{
      console.log("cliente relacionado", res.cliente);
      clienterel = res.cliente;
      //this.router.navigate([]).then(result => { window.open(`/dashboard`, '_blank'); });

      this.clienteService.setCliente2(clienterel);

      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/home/dashboard']));
   
         window.open('#' + url, '_blank')
      //this.router.navigate(['/home/dashboard']);
    });

  }


  public onSubmit(fData: any, formDirective: FormGroupDirective) {
    this.unsubscribe$.next();
    this.getClients();
    this.spinLoader = true;
    this.indOtro = false;
    let dcript = fData.value.dataOtro;//Recupera la descripcion
    let id_tag = fData.value.selected.id;
    let id_client = this.client.id;

    //

    if(fData.value.dataOtro == ''){
      console.log('Ingreso en elprimer caso');
      let request = {
        cliente_1: this.client.id,
        cliente_2: fData.value.clientName.id,
        relationship_tag_id: fData.value.selected.id
        //otro: fData.value.dataOtro
      };
      this.dashboardService.createRelation(request).subscribe(res => {
      this.toastr.success('Clientes relacionados');
      this.getClientsRelation();
      this.spinLoader = false;
      fData.reset();
      formDirective.resetForm();
      this.getClients();

      this.newForm = this.fb.group({
        clientName: ['', Validators.required],
        selected: ['', Validators.required],
        dataOtro:['']
      });


      // console.log("Esto es lo que me retorna el servidor: ", res);
      }, error => { 
        console.log("Ups, ha habido un error: ", error); 
      });
      console.log("request: ", request);

    }else {
      dcript = dcript.charAt(0).toUpperCase() + dcript.slice(1).toLowerCase(); // convierte la primera leta en mayuscula
      console.log('ingreso en el segundo caso')
      let request = {
        cliente_1: this.client.id,
        cliente_2: fData.value.clientName.id,
        relationship_tag_id: fData.value.selected.id,
        //otro: fData.value.dataOtro
      };
      this.dashboardService.createRelation(request).subscribe(res => {

        this.dashboardService.getEndRelationClient(id_client).subscribe(res =>{
          console.log(res);
          this.datarelaship = res.end_regis;
          this.id_relaship = this.datarelaship['id'];
          //console.log("Datos de la ultima relcion",this.datarelaship);
          //console.log("Id de la ultima relcion",this.id_relaship);
  
          let request2 = {

            id_relationship: this.id_relaship,
            id_tag_relation: id_tag,
            description: dcript,

            };
            //console.log("request del segundo caso: ", request2);

          this.dashboardService.registerTagOtro(request2).subscribe(res => {
            console.log('respuesta del servidor', res);

            this.toastr.success('Clientes relacionados');
            this.getClientsRelation();
            this.spinLoader = false;
            this.indOtro = false;
            fData.reset();
            formDirective.resetForm();
            this.getClients();

            this.newForm = this.fb.group({
              clientName: ['', Validators.required],
              selected: ['', Validators.required],
              dataOtro:['']
              
              
            });
            
            // console.log("Esto es lo que me retorna el servidor: ", res);
            }, error => { 
              console.log("Ups, ha habido un error: ", error); 
            });
            console.log("request: ", request);
          });
          //console.log("request del segundo caso: ", request2);
          //console.log("request del segundo caso: ", dcript);
          
        });

        // this.toastr.success('Clientes relacionados');
        // this.getClientsRelation();
        // this.spinLoader = false;
        // fData.reset();
        // formDirective.resetForm();
        // this.getClients();
        // // console.log("Esto es lo que me retorna el servidor: ", res);
        // }, error => { 
        //   console.log("Ups, ha habido un error: ", error); 
        // });
        // console.log("request: ", request);

      
    }
    // let request = {
    //   cliente_1: this.client.id,
    //   cliente_2: fData.value.clientName.id,
    //   relationship_tag_id: fData.value.selected.id
    //   //otro: fData.value.dataOtro
    // };
    // this.dashboardService.createRelation(request).subscribe(res => {
    //   this.toastr.success('Clientes relacionados');
    //   this.getClientsRelation();
    //   this.spinLoader = false;
    //   fData.reset();
    //   formDirective.resetForm();
    //   this.getClients();
    //   // console.log("Esto es lo que me retorna el servidor: ", res);
    // }, error => { 
    //     console.log("Ups, ha habido un error: ", error); 
    // });
    //console.log("request: ", request);
  }

  public generateFirstChart() {
    let ventasArray = [];
    let fechasArray = [];
    let ventas_fecha = [];
    for (let venta of this.ventas) {
      ventasArray.push(venta.venta);
      fechasArray.push(venta.fecha);
    }


    // Convirtiendo el array de strings a números.
    for (var i = 0; i < ventasArray.length; i++) { ventasArray[i] = parseInt(ventasArray[i], 10)}


    // Sumatoria de valores de todo el array de ventas.
    let sum = ventasArray.reduce((a, b) => a += b);
    // Promedio de ventas.
    let promedio = 40;
    let avg = sum / ventasArray.length;
    let max_prom = avg + ((promedio * avg) / 100);
    let min_prom = avg - ((promedio * avg) / 100);
    this.chartOptions = {
      series: [
        {
          name: "valor $",
          data: ventasArray
        }
      ],
      chart: {
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        width: "100%",
        height: 285,
        type: "line"
      },
      
      annotations: {
        yaxis: [
          {
            y: Math.max(...ventasArray),
            borderColor: "#FA836A",
            label: {
              borderColor: "red",
              offsetY: -15,
              style: {
                color: "#fff",
                background: "#FA836A"
              },
              text: "Venta máxima"
            }
          },
          {
            y: min_prom,
            y2: max_prom,
            borderColor: "#000",
            fillColor: "#FEB019",
            opacity: 0.2,
            label: {
              borderColor: "#333",
              offsetY: -5,
              offsetX: -5,
              style: {
                fontSize: "10px",
                color: "#333",
                background: "#FEB019"
              },
              text: "Rango promedio de ventas."
            }
          }
        ],
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: true,

        formatter: function (val, opt) {
          console.log(opt.w.globals);
          var Formatter = new Intl.NumberFormat('es-CO', {
                      style: 'currency',
                      currency: 'COP'
                  });

          //return opt.w.globals.labels[opt.dataPointIndex] + ': ' + Formatter.format(val);
          //return opt.w.globals.seriesNames[opt.seriesIndex] + ': ' + Formatter.format(val);
          return Formatter.format(val);
      }

      },
      stroke: {
        curve: "smooth"
      },
      grid: {
        padding: {
          right: 30,
          left: 20
        }
      },
      title: {
        text: "Ventas vs Fecha ",
        align: "left"
      },
      labels: fechasArray,
      xaxis: {
        labels: {
          
          // rotateAlways: f,
          hideOverlappingLabels: true,
          showDuplicates: true,
          trim: true
        }
      },

      yaxis: {
        labels: {
          formatter: function(val, index) {
            return val.toLocaleString('es-CO', {minimumFractionDigits: 0})
          }

        }
      },

      responsive: [{
        breakpoint: 769,
        options: {
          chart: {
            height: 420,
          },
          // grid: {
          //   padding: {
          //     right: 0,
          //     left: 0,
          //     bottom: 0,
          //     top: 0
          //   }
          // },
          annotations: {
            yaxis: [
              {
                y: Math.max(...ventasArray),
                borderColor: "#FA836A",
                label: {
                  borderColor: "red",
                  offsetY: -12,
                  style: {
                    color: "#fff",
                    background: "#FA836A"
                  },
                  text: "Venta máxima"
                }
              },
              {
                y: min_prom,
                y2: max_prom,
                borderColor: "#000",
                fillColor: "#FEB019",
                opacity: 0.2,
                label: {
                  borderColor: "#333",
                  offsetY: -5,
                  offsetX: -5,
                  style: {
                    fontSize: "10px",
                    color: "#333",
                    background: "#FEB019"
                  },
                }
              }
            ],
          },
        }
      }]
    };
  }

  // public generateSecondChart() {
  //   this.zone.runOutsideAngular(() => {

  //     // ACÁ VA EL SEGUNDO GRÁFICO
  //     this.chart_2 = am4core.create('bar_chart', am4charts.XYChart)
  //     this.chart_2.colors.step = 2;

  //     this.chart_2.legend = new am4charts.Legend()
  //     this.chart_2.legend.position = 'top'
  //     this.chart_2.legend.paddingBottom = 20
  //     this.chart_2.legend.labels.template.maxWidth = 95

  //     this.xAxis = this.chart_2.xAxes.push(new am4charts.CategoryAxis())
  //     this.xAxis.dataFields.category = 'category'
  //     this.xAxis.renderer.cellStartLocation = 0.1
  //     this.xAxis.renderer.cellEndLocation = 0.9
  //     this.xAxis.renderer.grid.template.location = 0;

  //     let yAxis = this.chart_2.yAxes.push(new am4charts.ValueAxis());
  //     yAxis.min = 0;
  //     this.chart_2.data = [
  //       {
  //         category: '#1',
  //         second: 35,
  //         third: 65
  //       },
  //       {
  //         category: '#2',
  //         second: 47,
  //         third: 23
  //       }
  //     ]
  //     this.createSeries('second', 'En Local');
  //     this.createSeries('third', 'Digital');
  //     this.chart_3 = this.chart_2;
  //   }) // Fin del zone.runOutsideAngular
  // } // Fin de ng ngAfterViewInit.

  // public generateChartDataNew() {
  //   let charData = [];
  //   for (let venta = 0; venta < this.ventas.length; venta++) {
  //     const element = this.ventas[venta];
  //     charData.push({
  //       fecha: element['fecha'],
  //       valor: element['venta']
  //     })
  //   }
  //   return charData;
  // }

  // private createSeries(value, name) {
  //   let series = this.chart_2.series.push(new am4charts.ColumnSeries())
  //   series.dataFields.valueY = value
  //   series.dataFields.categoryX = 'category'
  //   series.name = name
  //   let bullet = series.bullets.push(new am4charts.LabelBullet())
  //   bullet.interactionsEnabled = false
  //   bullet.dy = 30;
  //   bullet.label.text = '{valueY}'
  //   bullet.label.fill = am4core.color('#ffffff')
  //   return series;
  // }

  public agregarCumpleanos() {
    let newDate = JSON.stringify(this.fecha);
    newDate = newDate.substring(1, 11);
    console.log("Esto es fecha2: ", newDate);
    console.log("Este es el cliente: ", this.client.id);

    let request = {
      client_id: this.client.id,
      birthday_date: newDate
    };
    this.spinLoader = true;
    this.dashboardService.setBirthday(request).subscribe(res => {
      console.log("Esto es lo que me retorna el servidor: ", res);
      this.toastr.success('Fecha guardada satisfactoriamente');
      this.spinLoader = false;
      this.birthday = res.birthday;
    }, error => { 
        console.log('Ups, ha habido un error: ', error); 
    });
  }
  public changeClickDate(){
    this.isClickDate = !this.isClickDate;
    if(this.isClickDate){
      this.datesList =[];
      this.getDatesSpecial();
    }else{
      this.datesList;
    }
  }
  public agregarFecha(){
    let newDate = JSON.stringify(this.fecha);
    console.log(newDate);
    if(this.fecha==''){
      this.isDateInput = true;

    }else{
      this.isDateInput= false;
    }
    if(!this.type_fecha){
      this.isDateTypeInput = true;
    }else{
      this.isDateTypeInput= false;
    }
    if(this.isDateInput == false && this.isDateTypeInput == false){
      newDate = newDate.substring(1, 11);
      let request = {
        client_id: this.client.id,
        fecha_especial: newDate,
        type_fecha: this.type_fecha
      }
      this.fecha = '';
      this.changeClickDate();
      console.log(request);
      this.spinLoader = true;
      this.datesService.setSpecialDate(request).subscribe(res => {
        if(res.status == 'success'){
          console.log("Esto es lo que me retorna el servidor: ", res);
          this.toastr.success('Fecha guardada satisfactoriamente');
          
        }else{
          this.toastr.warning(res.message);
        }
        
        this.spinLoader = false;
      }, error => { 
          
          console.log('Ups, ha habido un error: ', error); 
      });
      this.getSpecialDates(); 
    }
    
  }
  public getSpecialDates(){
    this.datesService.getSpecialDate(this.client.nit).subscribe(res => {
     this.itemsDates = res.specialDates;
    }, error => {
      this.spinner.hide();
      console.log("Ups, ha habido un error: ", error);
    })
  }
  public openModalDeleteDate(modal,id,title,fecha){
    this.id_registro = id;
    this.title_registro = title;
    this.fecha_registro = fecha
    this.modal.open(modal, { size: 'md', centered: true, scrollable: true, windowClass: 'modal__class' }).result.then(result => {
      console.log("Esto es resulto: ", result);
    }, reason => { });
  }
  public onDeleteDate(){
    this.spinLoaderDelete = true;
    this.datesService.deleteSpecialDate(this.id_registro).subscribe(res => {
      this.modal.dismissAll();
      this.spinLoaderDelete = false;
      this.getSpecialDates();
     }, error => {
       this.spinner.hide();
       console.log("Ups, ha habido un error: ", error);
     })
  }

  public openModalDeleteRelation(modal,id,cliente,relation){
    this.id_relation_registro = id;
    console.log("Id relaion a eliminar", id)
    this.cliente_registro = cliente;
    this.relacion_registro = relation;
    this.modal.open(modal, { size: 'md', centered: true, scrollable: true, windowClass: 'modal__class' }).result.then(result => {
      console.log("Esto es resulto: ", result);
    }, reason => { });
  }

  public onDeleteRelation(){
    this.spinLoaderDelete = true;
    this.dashboardService.deleteRelation(this.id_relation_registro).subscribe(res => {
      console.log("Respuesta de eliminar relacion", res)
      this.modal.dismissAll();
      this.spinLoaderDelete = false;
      this.getClientsRelation();
     }, error => {
       this.spinner.hide();
       console.log("Ups, ha habido un error: ", error);
     })
  }


  ngOnDestroy() {
    // this.zone.runOutsideAngular(() => {
      // if (this.chart || this.chart_3) {
      //   // am4core.disposeAllCharts();
      //   this.chart_2.dispose();
      //   this.chart_3.dispose();
      // }
    // });
  }
  // changeState() {
  //   this.newForm.get('selected').valueChanges
  //     .subscribe(res => {
  //       console.log('respuesta', res)
      
  //       if (res === "Otro") { 
  //         this.newForm.get('customInput').enable() }
  //       else { this.newForm.get('customInput').disable() }
  //     });
  // }

}
