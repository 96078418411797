import { map } from 'rxjs/operators';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { global } from './../global';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  public url: string;
  constructor(private http: HttpClient) {
    this.url = global.url_crm;
  }

  public requestToken(): Observable<any> {
    let headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded'
    });
    const body = new HttpParams()
    .set('grant_type','password')
    .set('username', 'admin')
    .set('password', '1234')
    .set('client_id', '61519d76-3c10-e50e-ae3f-5fbdd170707b')
    .set('client_secret', '123');
    return this.http.post(this.url + 'access_token', body.toString(), { headers: headers });
  }

  public getContactsFromCrm(token): Observable<any> {
    // console.log("Este es el token gcsfc: ", token);
    let headers = new HttpHeaders()
      .set('Content-Type', 'Content-Type: application/json')
      .set('Authorization', 'Bearer ' + token);

    return this.http.get(this.url + 'V8/module/Contacts?sort=-name', { headers: headers });
    // return this.http.get(this.url + 'V8/module/Contacts?fields[Contacts]=name,last_name,description,phone_mobile', { headers: headers});
  }

  public getContanctFromCrm(data: any, token): Observable<any> {
    let params = 'json=' + data;
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.post(this.url + 'V8/getClient', params, { headers: headers });
  }

  public getHistoryContact(data: any, token): Observable<any> {
    let params = 'json=' + data;
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.post(this.url + 'V8/getHistoryContact', params, { headers: headers });
  }

  public createNewCrmContact(data: any, token): Observable<any> {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-type': 'application/x-www-form-urlencoded'
    });
    const body = new HttpParams()
      .set('first_name', data.first_name)
      .set('last_name', data.last_name)
      .set('email', data.email)
      .set('user_description', data.user_description)
      .set('tag_description', data.tag_description)
      .set('event_description', data.event_description);
    
      return this.http.post(this.url + 'V8/createUser', body.toString(), { headers: headers });
  }

  public addHistoryContact(id: any, data: any, token: any): Observable<any> {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-type': 'application/x-www-form-urlencoded'
    });
    const body = new HttpParams()
      .set('name', data.tag_description)
      .set('description', data.event_description)
      .set('contact_id', id);
    return this.http.post(this.url + 'V8/createHistoryOfContact', body.toString(), { headers: headers });
  }
}
