import { ActivatedRoute } from '@angular/router';
import { DashboardService } from 'src/app/services/Dashboard/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { Ventas } from 'src/app/models/ventas';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientesService } from 'src/app/services/Clientes/clientes.service';
import { Clientes } from 'src/app/models/clientes';
import { global } from 'src/app/services/global';
import { TrmService } from 'src/app/services/Trm/trm.service';
import { Trm } from 'src/app/models/trm';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-detalle-producto',
  templateUrl: './detalle-producto.component.html',
  styleUrls: ['./detalle-producto.component.scss']
})
export class DetalleProductoComponent implements OnInit {

  public id: any;
  public is_inventory:string = "false";
  public nombreCliente: string;
  public numLinea: number;
  public linea: string;
  public numGrupo: number;
  public grupo: string;
  public marca: string;
  public cantidad: number;
  public refVernier: string;
  public refProveedor: string;
  public serial: string;
  public fecha: string;
  public precio: number;
  public elemento: string;
  public descripcion: string;
  public items_recomendados: Array<Ventas[]>;
  public client: any;
  public url_image: string = global.url_image;
  public precio_dolar:number=0;

  public trm_data:Trm[];
  public mesage: string;
  public trm_ob: number;
  public trm_date: string;

  

  constructor(
    private dashboard: DashboardService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private clientService: ClientesService,
    private TrmService: TrmService
  ) { }

  ngOnInit(): void {
    this.clientService.itemsRecomendados.subscribe(res => {
      console.log("me llega del observable: ", res);
      this.id = res;
      // this.id = this.id;
      this.getPageInfo();
    });


    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.is_inventory = this.activatedRoute.snapshot.paramMap.get('is_inventory');
    console.log("Este es el id: ", this.id);
    this.getPageInfo();
    this.getClient();
    this.dataTrm();
  }

  public dataTrm(){
    this.TrmService.getTrm().subscribe(res =>{
      this.trm_data = res.trmf;
      this.trm_ob = this.trm_data['trm'];
      this.trm_date = this.trm_data['created_at'];
      
    })

  }

  private getClient() {
    this.client = JSON.parse(sessionStorage.getItem('cliente'));
  }

  private getPageInfo() {
    this.spinner.show();
    console.log("este es el id dentro de getPage: ", this.id);
    this.dashboard.detalleProductoPageInfo(this.id).subscribe(res => {
      console.log("Esto es lo que me devuelve el servicio: ", res);
      // this.nombreCliente = res.producto.nombre_cliente;
      this.numLinea = res.producto.linea;
      this.linea = res.producto.nombre_linea;
      this.numGrupo = res.producto.grupo;
      this.grupo = res.producto.nombre_grupo;
      this.marca = res.producto.marca;
      this.elemento = res.producto.elemento;
      this.cantidad = res.producto.saldo;
      // this.refVernier = res.producto.ref_vernier;
      this.refProveedor = res.producto.ref_proveedor;
      this.serial = res.producto.serial;
      // this.fecha = res.producto.fecha_venta;
      this.precio = res.producto.precio;
      console.log('Precio', res.producto.precio);
      this.descripcion = res.producto.descripcion;
      this.items_recomendados = JSON.parse(sessionStorage.getItem('items_recomendados'));
      this.url_image = this.url_image+res.producto.img_name+'.png';
      console.log('Dolares', res.producto.dolar);
      if(res.producto.dolar){
        this.precio_dolar =  res.producto.dolar;
      }
      // console.log("Estos son los items recomendados: ", this.items_recomendados);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log("Ups, ha habido un error: ", error);
    });
  }

  public changeData(id: any) {
    this.clientService.changeRecomendedItems(id);
  }

  toggleFavorite(item: any) {
    let data = {
      cliente: this.client.id,
      ref_product: item.id
    };
    this.dashboard.setFavoriteItem(data).subscribe(res => {
      this.items_recomendados = res.new_items;
      sessionStorage.setItem('items_recomendados', JSON.stringify(this.items_recomendados));

    }, error => {
        console.log('Ups, ha habido un error: ', error); 
    });
  }
  public changeClass(is_inventory:string){
    return {
      'col-lg-9 col__9': is_inventory=="false",
      'col-lg-12 col__12': is_inventory=="true"
    }
  }
  changeLink(is_inventory){
    return {
      '[/home/dashboard]': is_inventory=="false",
      '[/home/productos-inventario]': is_inventory=="true"
    }
  }
  downloadPdf(reference:string){
    console.log("entro al pdf");

    const element: Element = document.getElementById('first__row')
    let hgt = element.clientHeight;
    //let hgt = element.scrollHeight;
    //let wdt =  element.scrollWidth;

    const options ={
      margin: [5, 5, 0, 5],
      filename:reference+'.pdf',
      name:'output.pdf',
      image:{type:'png'},

      html2canvas:{
        
        scale:2,
        height: hgt+110,
        //height: hgt,

        useCORS: true
      },
      jsPDF:{orientation:'p', format: "letter", compress:true}
    }

    console.log(options);
    console.log(element);
    html2pdf()
      .from(element)   
      .set(options)
      .save()
  }

  downloadPdf_movil(reference:string){
    console.log("entro al pdf");

    const element: Element = document.getElementById('first__row')
    let hgt = element.clientHeight;
    //let hgt = element.scrollHeight;
    //let wdt =  element.scrollWidth;

    const options ={
      margin: [2, 10, 0, 10],
      filename:reference+'.pdf',
      name:'output.pdf',
      image:{type:'png'},

      html2canvas:{
        
        scale:2,
        //height: hgt+30,
        height: hgt+50,
        //height: hgt,

        useCORS: true
      },
      jsPDF:{orientation:'p', format: "a4", compress:true}
    }

    console.log(options);
    console.log(element);
    html2pdf()
      .from(element)   
      .set(options)
      .save()
  }

}


