import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TrmService } from 'src/app/services/Trm/trm.service';
import { Trm } from 'src/app/models/trm';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-trm',
  templateUrl: './trm.component.html',
  styleUrls: ['./trm.component.scss']
})
export class TrmComponent implements OnInit,  AfterViewInit {


  formTrm: FormGroup;
  public status: string;
  public message: string;



  constructor(

    private router: Router,
    public formulario: FormBuilder, 
    private trmService: TrmService,
    private spinner: NgxSpinnerService,
  
  ) { 


    this.formTrm = this.formulario.group({
      trm:[0],
    });
    this.status = '';
    this.message = '';
  }

  ngOnInit(): void {
    this.spinner.show();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.spinner.hide();
  }



  enviarDatos():any {
    console.log('Me presionaste ');
    console.log(this.formTrm.value);
    this.trmService.register(this.formTrm.value).subscribe(

      response => {
        if (response.status == 'success') {
          this.status = response.status;
          this.spinner.hide();
          this.formTrm = this.formulario.group({
            trm:[0],
          });
          // form.reset();
        } else {
          this.spinner.hide();
          this.status = response.status;
        }
      }


    );
  }

  public goToDashboard() {
    this.router.navigate(['home/dashboard']);
  }




}
