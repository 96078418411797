<div class="content_general">
    <div class="row no-gutters general__row">
        <nav class="navbar p-0 py-4 align-middle w-100">
            <a class="navbar-brand p-0 w-100 align-middle">
                <img [routerLink]="['/home/dashboard']" class="align-middle img__black__back" src="assets/img/detalleProductoPage/back_arrow.svg">
                <span class="align-middle ml-2 w-100 title__span">Lista de Productos</span>
                <div>
                    <h1 style="display: block; margin: 0;"></h1>
                    <h1 style="display: block; margin: 0;"></h1>
                </div>
            </a>
        </nav>

        <div class="row m-0 p-0 w-100">
            <div class="cards">
                <div class="card" *ngFor="let item of items_recomendados">
                    <img src="{{url_image + item.img_name + '.png'}}" onError="this.src='assets/img/listaProductosPage/new_cut.png'" alt="" class="card__image">
                    <div class="card__content text-center">
                        <h4>{{ item.descripcion }}</h4>
                        <h4>#{{ item.referencia }}</h4>
                        <h4>${{ item.precio | number: '1.0-0' }}</h4>
                    </div>
                    <div class="card__info text-center" [routerLink]="['/home/detalle-producto', item.referencia,false]" routerDirection="forward">
                        <span>Ver detalles <i class="ml-2 fa fa-arrow-right"></i></span>
                        <!-- <a class="card__link">View Article</a> -->
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>