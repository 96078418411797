import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from '../global';

import { Trm } from '../../models/trm';

@Injectable({
  providedIn: 'root'
})
export class TrmService {

  public url: string;
  public identity: any;
  public token: any;

  constructor(
    public _http: HttpClient, 
  ) { 
    this.url = global.url;
  }

  public register(trm: Trm): Observable<any> {
    //let json = JSON.stringify(trm);
    //let params = 'json=' + json;

    //console.log(params)

    //let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.post(this.url + 'trm', trm);
  }

  public getTrm(): Observable<any> {
    //let json = JSON.stringify(trm);
    //let params = 'json=' + json;

    //console.log(params)

    //let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    console.log(this._http.get(this.url + 'trm'));
    return this._http.get(this.url + 'trm');
  }



}
