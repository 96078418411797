<div class="page">
    <div class="row no-gutters h-100" style="background-color: #FBF6F2;">
        <div class="col-lg-9 col__9">
            <nav class="navbar p-0 py-3 align-middle">
                <a class="navbar-brand p-0 w-100 align-middle">
                    <img class="align-middle" [routerLink]="['/home/dashboard']" routerDirection="forward" src="assets/img/detalleProductoPage/back_arrow.svg" style="cursor: pointer;">
                    <span class="align-middle ml-2 w-100" id="detalle__compra">Detalle la compra</span>
                    <div>
                        <h1 style="display: block; margin: 0;"></h1>
                        <h1 style="display: block; margin: 0;"></h1>
                    </div>
                </a>
            </nav>
            <div class="row m-0" id="first__row">
                <div class="col-sm-7 p-0 flex__xs">
                    <div class="pr-1 border-0 order-xs-2" id="image__container">
                        <img [src]="url_image" class="w-100 h-100" onError="this.src='assets/img/detalleCompraPage/box.svg'">
                        <!-- <i id="elipses" class="fas fa-ellipsis-v"></i> -->
                    </div>
                    <h4 class="mt-3 order-xs-1" id="description__producto">
                        {{ description }}
                    </h4>
                </div>
                <div class="col-sm-5" id="column__5">
                    <div id="container__description">
                        <div class="card h-100" style="border-radius: 0;">
                            <div class="card-body pt-0">
                                <h5 class="card-title mt-3">Especificaciones</h5>
                                <div class="row no-gutters" style="height: 90%; letter-spacing: 1px; padding-bottom: 2%;">
                                    <div class="col-7 border-right">
                                        <h3 class="title_description">CLIENTE</h3>
                                        <h3 class="description">{{ nombreCliente }}</h3>

                                        <h3 class="title_description mt-2">NÚMERO DE LINEA</h3>
                                        <h3 class="description mt-1">{{ numeroLinea }}</h3>

                                        <h3 class="title_description mt-2">LINEA</h3>
                                        <h3 class="description mt-1">{{ nombreLinea }}</h3>

                                        <h3 class="title_description mt-2">NÚMERO DE GRUPO</h3>
                                        <h3 class="description mt-1">{{ numeroGrupo }}</h3>

                                        <h3 class="title_description mt-2">GRUPO</h3>
                                        <h3 class="description mt-1">{{ nombreGrupo }}</h3>

                                        <h3 class="title_description mt-2">MARCA</h3>
                                        <h3 class="description mt-1">{{ nombreMarca }}</h3>
                                    </div>
                                    <div class="col-5 pl-2">
                                        <h3 class="title_description">CANTIDAD</h3>
                                        <h3 class="description mt-1">{{ cant }}</h3>

                                        <h3 class="title_description">ID SIIGO</h3>
                                        <h3 class="description mt-1">{{ id }}</h3>

                                        <h3 class="title_description mt-2">REF VERNIER</h3>
                                        <h3 class="description mt-1">{{ refVernier }}</h3>

                                        <h3 class="title_description mt-2">REF PROVEEDOR</h3>
                                        <h3 class="description mt-1">{{ refProveedor }}</h3>

                                        <h3 class="title_description mt-2">SERIAL</h3>
                                        <h3 class="description mt-1">{{ serial }}</h3>

                                        <h3 class="title_description mt-2">FECHA</h3>
                                        <h3 class="description mt-1">{{ fecha_venta }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer justify-content-center text-center">
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-3 card w-100" id="card__price">
                        <div class="card-body px-0 my-auto justify-content-center align-items-center ">
                            <h3 id="precio__articulo">Precio del artículo</h3>
                            <h3 id="precio__producto" class="pt-3 pb-1">${{ precioProducto | number: '1.0-0' }}</h3>
                        </div>
                        <div class="card-footer px-0 text-muted">
                            <span class="text-success mx-auto"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col__3">
            <div class="w-100 px-3" id="third__column">
                <nav class="px-2 navbar navbar-expand-lg" style="height: 60px;">
                    <a class="navbar-brand recomendados__tag">Recomendados</a>
                    <div class="collapse navbar-collapse w-100" id="navbarNavDropdown">
                        <!-- <ul class="navbar-nav w-100 text-right">
                            <div class="dropdown w-100">
                                <button type="button" class="dropdown__settings btn dropdown-toggle" data-toggle="dropdown">
                                    Todos
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item" href="#">Link 1</a>
                                    <a class="dropdown-item" href="#">Link 2</a>
                                    <a class="dropdown-item" href="#">Link 3</a>
                                </div>
                            </div>
                        </ul> -->
                    </div>
                </nav>

                <div class="mt-1 pr-1" style="height: calc(100% - 63px);">
                    <div class="table-responsive h-100">
                        <table class="table table-borderd table-hover h-100">
                            <tbody>
                                <tr *ngFor="let item of items_recomendados" class="d-flex align-items-star px-1 items__list my-2">
                                    <th>
                                        <!-- <img style="width: 20%; border-radius: 10px;" src="assets/img/dashboardPage/tshuc.png"> -->
                                        <div class="ml-0 my-auto" style="display: block;">
                                            <h6 [routerLink]="['/home/detalle-producto', item.referencia,false]" routerDirection="forward" class="m-0 etiqueta__titulo_item align-middle">
                                                {{ item.descripcion }}</h6>
                                            <div class="favorite__container">
                                                <div class="favorite__container_first">
                                                    <h6 class="mt-2 etiqueta__description_item">{{ item.referencia }}
                                                    </h6>
                                                    <h6 class="m-0 mt-1 align-middle" style="color: #3BD66A; font-size: .8rem;">
                                                        ${{ item.precio | number: '1.0-0' }}</h6>
                                                </div>
                                                <!-- <div class="favorite__container_second" (click)="toggleFavorite(item)">
                                                    <i *ngIf="item.favorite == 0" class="far fa-heart"></i>
                                                    <i *ngIf="item.favorite == 1" class="fas fa-heart"></i>
                                                </div> -->
                                            </div>
                                        </div>
                                        <!-- <div class="my-auto mx-auto" style="display: block;">

                                        </div> -->
                                    </th>
                                </tr>

                                <!-- <th class="d-flex align-items-star items__list mt-3">
                                    <img class="image__items" src="assets/img/dashboardPage/tshuc.png">
                                    <div class="ml-2 my-auto container__info">
                                        <h6 class="m-0 etiqueta__titulo_item align-middle">Nombre Artículo</h6>
                                        <h6 class="mt-1 etiqueta__description_item">#Referencia</h6>
                                    </div>
                                    <div class="my-auto text-center w-100 container__info">
                                        <h6 class="m-0 align-middle price__tag">
                                            $3.594</h6>
                                    </div>
                                </th> -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="w-100 p-0 pt-1" id="container__total__price">
                <div class="row no-gutters h-100" style="margin-bottom: 34px;">
                    <div class="col-5 text-center my-auto">
                        <h1 style="font-family: 'Source Sans Pro', sans-serif;
                            font-weight: 400; color: #e2bb99; font-size: 1rem; margin:0">Total Venta:</h1>
                    </div>
                    <div class="col-7 text-center my-auto">
                        <h1 style="font-family: 'Source Sans Pro', sans-serif;
                            font-weight: 300; color: #e2bb99; font-size: 2rem; margin: 0;">${{ valor | number: '1.0-0' }}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-climbing-dot">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>