import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from 'src/app/services/User/user.service';

@Injectable({
  providedIn: 'root'
})
export class IntroShowedGuard implements CanActivate {
  
  constructor(
    private router: Router,
    private userService: UserService
  ) {
    
  }

  async canActivate() {
    const autenticationValidator = await this.userService.getIdentity();
    if (autenticationValidator) {
      this.router.navigate(['search-page']);
    } else {
      return true;
    }
  }
}
