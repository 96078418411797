import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../../services/User/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router
  ) {
    
  }
  async canActivate() {
    const autenticationValidator = await this.userService.getIdentity();
    if (autenticationValidator) {
      return true;
    } else {
      this.router.navigate(['intro']);
    }
  }
  
}
