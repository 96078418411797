import { Component, OnInit,Input,Output,EventEmitter  } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Inventario } from 'src/app/models/inventario';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-inventario-list',
  templateUrl: './inventario-list.component.html',
  styleUrls: ['./inventario-list.component.scss']
})
export class InventarioListComponent implements OnInit {
  @Input() products:Inventario[] = [];
  @Input() page:number;
  @Input() count:number;
  @Input() pageSize:number;
  @Input() pageSizes = [];
  @Output() search_products: EventEmitter<string> = new EventEmitter();
  @Output() pageCurrrent: EventEmitter<number> = new EventEmitter();
  @Output() sizePage: EventEmitter<number> = new EventEmitter();
  
  public search_input:string;
  public myControl = new FormControl();
  constructor(private activatedRoute: ActivatedRoute) { }
  ngOnInit(): void {
    console.log('Prouctos', this.products)
  }
  onSubmitSearch(form){
    this.search_products.emit(this.search_input);
  }
  pageChange(event){
    this.pageCurrrent.emit(event);
  }
  PageSizeChange(event){
    this.sizePage.emit(event.target.value);
  }
}

