import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from '../global';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  public url: string;

  constructor(public _http: HttpClient, ) { this.url = global.url; }


  public getAllVideos(): Observable<any> {
    //let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.get(this.url + 'file');
  }

  public getVideoAct(estado: number): Observable<any> {
    //let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'file/' + estado);
  }



  public updataVideo(estado:number, data: any): Observable<any>{
    let json = JSON.stringify(data);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    console.log("reques", json);

    return this._http.put(this.url + 'file/update/' + estado, data);
  }
}
