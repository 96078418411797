
<div class="box font-small pt-sm-1 pt-lg-2" >
    <div class="row mt-5 w-100 h-100 general__row">
        <div class="col-lg-9 w-100 h-100">
            <!-- EMPIEZA EL FORMULARIO -->
            <form [formGroup]="formTrm"  (ngSubmit)="enviarDatos()">
                <!-- (ngSubmit)="onSubmitRegister(registerForm)" -->

                <div class="md-form mt-3 " class="form-group">
                    <div class="md-form ">

                        <mat-form-field class="mt-auto" appearance="legacy">
                            <input type="number" formControlName="trm" name="trm" placeholder="Ingrese la TRM"  required matInput>
                            <!-- #name="ngModel" [(ngModel)]="user.name" -->
                        </mat-form-field>

                        <!-- <small *ngIf="!name.valid && name.touched" class="invalid-feedback d-block">
                    El nombre no es válido.
                </small> -->
                    </div>
                </div>

                <!-- <div *ngIf="status == 'error'" class="mb-2 alert alert-danger d-blok">
                    El registro no se ha podido completar. <span *ngIf="message">{{message}}</span>
                </div> -->

                <div *ngIf="status == 'success'" class="mb-2 alert alert-success d-blok">
                    Registro completado satisfactoriamente. <span *ngIf="message">{{message}}</span>
                </div> 

                <div class="d-flex mt-3 justify-content-between bd-highlight mb-1 my-2">
                    <button type="submit" class="btn w-100 mx-auto font-button" >Registrar la TRM</button>
                </div>
            </form>
            <div class="d-flex mt-4 justify-content-between bd-highlight mb-1 my-2">
                <button (click)="goToDashboard()" class="btn w-100 mx-auto font-button">Regresar a Dashboard</button>
            </div>
        </div>
    </div>
</div>

