<nav class="py-1 pl-5 navbar navbar-expand-lg" style="background-color: white;">
    <a class="navbar-brand" style="cursor: pointer;">
        <img [routerLink]="['/home/dashboard']" style="width: auto;" src="assets/img/dashboardPage/logo_header.svg">
    </a>
    <button class="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <img src="../../../assets/img/menu.svg" class="navbar-toggler-icon my-toggler">
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">

        </ul>

        <ul class="navbar-nav navbar-right w-100 d-flex justify-content-end">
            <li id="li__1" class="nav-item w-50">
                <form #searchForm="ngForm" (ngSubmit)="onSubmitSearch(searchForm)" class="my-2 my-md-0 w-100" style="display: flex; align-items: baseline;">
                    <input [(ngModel)]="variable" [formControl]="myControl" class="form-control input__class mr-sm-0" matInput [matAutocomplete]="auto" type="search" placeholder="Buscar Cliente" aria-label="Search">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let cliente of filteredOptions | async" [value]="cliente" class="mat__option">
                            {{cliente.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                    <button class="btn__class btn btn-outline-dark my-2 my-sm-0 mr-md-3" type="submit">
                        <img class="search_image" src="../../../assets/img/searchPage/icon_search.svg">
                    </button>
                </form>
            </li>


            <!--  <li id="li__2" class="nav-item my-auto" style="display: flex;">
                <ul [routerLink]="['/home/user-register']" routerDirection="forward" class="mx-2" style="width: auto; cursor: pointer; margin:0; padding: 0;">
                    <i class="fas fa-user-edit"></i>
                </ul>
                <ul [routerLink]="['/home/data-import']" routerDirection="forward" class="mx-2" style="width: auto; cursor: pointer; margin:0; padding: 0;">
                    <i class="fas fa-chart-bar"></i>
                </ul>
                <ul [routerLink]="['/home/user-list']" routerDirection="forward" class="ml-2 mr-3" style="width: auto; cursor: pointer; margin:0; padding: 0;">
                    <i class="fas fa-users"></i>
                </ul>
            </li> -->

            <div class="dropdown show center-vertical">
                <a class="dropdown-toggle dropdown_menu" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Opciones
                </a>

                <div class="dropdown-menu " aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" [routerLink]="['/home/user-register']"><i class="fas fa-user-edit"></i>Registro de usuario</a>
                    <a class="dropdown-item" [routerLink]="['/home/data-import']"><i class="fas fa-chart-bar"></i>Importar datos</a>
                    <a class="dropdown-item" [routerLink]="['/home/user-list']"><i class="fas fa-users"></i>Gestión de usuario</a>
                    <a class="dropdown-item" [routerLink]="['/home/productos-inventario']"><i class="fas fa-clipboard-list"></i>Inventario</a>
                    <a class="dropdown-item" [routerLink]="['/home/trm']"><i class="fas fa-dollar-sign"></i>TRM</a>
                    <a class="dropdown-item" [routerLink]="['/home/video']"><i class="fas fa-file-video"></i>Video</a>
                
                </div>
            </div>
            <li id="li__3" style="letter-spacing: 1px;" class="nav-item dropdown ml-1 pl-0" *ngIf="identity && identity.name">
                <a class="nav-link dropdown-toggle dropdown__navbar border-left" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{identity.name + ' ' + identity.surname}}
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="#">Buscar Cliente</a>
                    <!-- <a class="dropdown-item" href="#">Another action</a> -->
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" (click)="logout()" routerDirection="forward">Cerrar Sesión</a>
                </div>
            </li>
            <li id="li__4" class="nav-item" style="width: 5%;">
                <!-- <img class="rounded-circle mt-1 mx-auto w-50" src="assets/img/avatar.png"> -->
                <img style="background-color: white !important; width: 40px;" class="rounded-circle mr-2" src="assets/img/avatar.png">
            </li>
        </ul>

    </div>
</nav>

<div class="w-100" style="background-color: #FBF6F2; height: calc(100% - 55.09px);">
    <router-outlet></router-outlet>
</div>
<!-- 
<router-outlet></router-outlet> -->



<footer class="footer text-center py-3 ">
    <u>Términos y condiciones</u> - Política de manejo de Datos - Andre Laurent 2020 @ All Rights Reserved
</footer>