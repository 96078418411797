<div class="card text-center">
    <div class="card-header">
        <h3>Configurar video</h3>
    </div>
</div>

<div class="contain">
    <div class="row no-gutters h-100" style="background-color: #FBF6F2;">
        <div class="col-sm-12 col-lg-9 general__col" style="background-color: transparent; text-align: center;">
            <h3 id="title">Videos disponibles</h3>

            <div class="row m-0 p-0 w-100">
                <div class="cards cards_2">
                    <div class="card_1" *ngFor="let item of videos">


                        <video (click)="onSelectClient(item)" width="95%" height="85%"  loop [muted]="true" autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" src="{{url_videos + item.name_video}}" type="video/mov">
                        </video>

                        <div class="card__content text-center">
                            <h2>{{ item.name_video }}</h2>
                        </div>

                        <div *ngIf="item.estado ==1" class="videoselect">Video eleccionado</div>

                    </div>
                </div>
            </div>
    




        </div>
        
        <div class="col-lg-3 second__col">
            <div class=" w-100 h-100 general__col" style="text-align: center;">
                <!-- EMPIEZA EL FORMULARIO -->
                <form #loadDataForm="ngForm" (ngSubmit)="onSubmitData(loadDataForm)">
    
                    <h3 id="title">Subir el video</h3>
                    <angular-file-uploader class="file__uploader" [resetUpload]=" resetVar " [config]="afuConfig" (click)="onLoadSpinner($event)" (ApiResponse)="dataUpload($event)">
                    </angular-file-uploader>
                    <!-- <div class="custom-file mt-3 mb-1 ">
                        <input type="file " class="custom-file-input h-100 " name="data " #data="ngModel " [(ngModel)]="file ">
                        <label class="custom-file-label h-100 m-0 " for="data ">{{ label }}</label>
                    </div> -->
    
                    <div *ngIf="status=='error' " class="info__message mb-2 alert alert-danger d-blok ">
                        Error al subir archivo. <span *ngIf="message ">{{message}}</span>
                    </div>
    
                    <div *ngIf="status=='success' " class=" info__message mb-2 alert alert-success d-blok "><span *ngIf="message ">{{message}}</span>
                    </div>
    
                    <!-- <div class="d-flex mt-3 justify-content-between bd-highlight mb-1 my-2 ">
                        <button type="submit " class="btn w-100 mx-auto font-button " [disabled]="loadDataForm.invalid ">Subir
                            archivo</button>
                    </div> -->
                </form>
                <hr>
            </div>

        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-climbing-dot">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>