import { Observable } from 'rxjs';
import { global } from '../global';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  public url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = global.url;
  }

  public getClientData(idCliente: number): Observable<any>{
    let id = JSON.stringify(idCliente);

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    console.log("Info del cliente:"+this.http.get(this.url + 'getClientData/' + id, { headers: headers }));

    return this.http.get(this.url + 'getClientData/' + id, { headers: headers });
  }

  public getEndRelationClient(idCliente: number): Observable<any>{
    let id = JSON.stringify(idCliente);

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    console.log("Info de relacion:"+this.http.get(this.url + 'idrelation/' + id, { headers: headers }));

    return this.http.get(this.url + 'idrelation/' + id, { headers: headers });
  }
  

  public detalleCompraPageInfo(id_siigo): Observable<any>{
    let id = JSON.stringify(id_siigo);

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(this.url + 'detalleCompraPageInfo/' + id, { headers: headers });
  }

  public detalleProductoPageInfo(ref: any): Observable<any>{
    let id = JSON.stringify(ref);

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(this.url + 'detalleProductoPageInfo/' + ref, { headers: headers });
  }

  public setBirthday(data: any): Observable<any>{
    let json = JSON.stringify(data);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(this.url + 'createBirthday', params, { headers: headers });
  }

  public createRelation(data: any): Observable<any>{
    let json = JSON.stringify(data);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(this.url + 'createRelationship', params, { headers: headers });
  }

  
  public registerTagOtro(data: any): Observable<any>{
    let json = JSON.stringify(data);
    console.log('Data en el meodo', data);
    let params = 'json=' + json;
    console.log('Data en el meodo, params ', params);

    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + 'registerothertag', json, { headers: headers });
  }

  public getRelatedCustomers(data: any): Observable<any>{
    let json = JSON.stringify(data);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(this.url + 'getRelatedCustomers', params, { headers: headers });
  }

  public setFavoriteItem(data: any): Observable<any> {
    let json = JSON.stringify(data);
    let params = 'json=' + json;
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(this.url + 'setFavoriteItem', params, { headers: headers });
  }

  public deleteRelation(idRelation: number): Observable<any>{
    let id = JSON.stringify(idRelation);

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    console.log("Eliminado relaion", this.http.get(this.url + 'relation/delete/' + idRelation))
    return this.http.get(this.url + 'relation/delete/' + idRelation);
  }
}
