import { Clientes } from './../../models/clientes';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/services/User/user.service';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ClientesService } from 'src/app/services/Clientes/clientes.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [UserService]
})
export class HomeComponent implements OnInit, OnDestroy {
  mySubscription: any;
  public identity;
  public token;
  public variable;
  public clientes_new: Clientes[];
  public cliente_Actualizado: Clientes;
  public status: string;
  public myControl = new FormControl();
  public filteredOptions: Observable<Clientes[]>;

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private clientService: ClientesService,
    public _userService: UserService
  ) {
    this.identity = this._userService.getIdentity();
    this.variable = '';
    this.status = '';



  }
  ngOnInit(): void {
    this.getClients();
    this.clientService.changeClientLists.subscribe(res => {
      console.log("Esta es la rta del observable en home.ts: ", res);
      if (res) {
        this.getClients();
      }
    });
  }

  async getClients() {

    await this.clientService.getAllClientes().subscribe(res => {
      this.clientes_new = res.clients;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.nombre),
          map(name => name ? this._filter(name) : this.clientes_new.slice())
        );
    });

  }

  public onSubmitSearch(form) {
    // console.log("Esto es input: ", this.variable);
    if (this.variable.nit) {
      //console.log("Entramos acá");
      this.status = 'success';
      this.clientService.setCliente(this.variable);
      this.router.navigate(['/home/dashboard']);
      //this.clientService.setCliente(this.variable);
      //  this.router.navigate(['/search-page']).then(() => {
      //    this.router.navigateByUrl('/home');
      // });
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      // this.router.onSameUrlNavigation = 'reload';
      // this.router.navigate(['/home/dashboard']);
      // am4core.disposeAllCharts();

    } else {
      this.status = 'error';
    }
  }

  displayFn(cliente: Clientes): string {
    return cliente && cliente.nombre ? cliente.nombre : '';
  }

  private _filter(name: string): Clientes[] {
    const filterValue = name.toLowerCase();
    return this.clientes_new.filter(cliente =>
      cliente.nombre &&
      cliente.nombre.toLowerCase().indexOf(filterValue) === 0);
  }

  public logout() {
    //console.log("Entramos a logout");
    localStorage.removeItem('token');
    localStorage.removeItem('identity');
    sessionStorage.removeItem('identity');
    sessionStorage.removeItem('cliente');
    sessionStorage.removeItem('items_recomendados');

    this.identity = null;
    this.token = null;

    // Redirección a la página principal.
    this.router.navigate(['intro']);

  }

  ngOnDestroy() {

  }
}
