import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Ventas } from 'src/app/models/ventas';
import { DashboardService } from 'src/app/services/Dashboard/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { global } from 'src/app/services/global';

@Component({
  selector: 'app-detalle-compra',
  templateUrl: './detalle-compra.component.html',
  styleUrls: ['./detalle-compra.component.scss']
})
export class DetalleCompraComponent implements OnInit {

  public venta: Ventas;
  public numeroLinea: number;
  public nombreLinea: string;
  public nombreCliente: string;
  public numeroGrupo: string;
  public nombreGrupo: string;
  public nombreMarca: string;
  public refVernier: string;
  public refProveedor: string;
  public serial: string;
  public precioProducto: number;
  public id: number;
  public description: string;
  public cant: number;
  public valor: number;
  public fecha_venta: string;
  public items_recomendados: Array<Ventas[]>;
  public client: any;
  public url_image: string = global.url_image;
  // public url_image_prueba: string = 'http://localhost/dashboard/andre-laurent-new/andre-laurent/storage/app/images/CT43APBA.21-1.11.png';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
  ) {
    this.numeroLinea = 1;
    this.nombreLinea = '';
    this.numeroGrupo = '';
    this.nombreGrupo = '';
    this.nombreMarca = '';
    this.refVernier = '';
    this.refProveedor = '';
    this.serial = '';
    this.precioProducto = 1;
    this.spinner.show();
  }

  ngOnInit(): void {
    this.id = +this.activatedRoute.snapshot.paramMap.get('id');
    // this.venta = new Ventas(
    //   +this.activatedRoute.snapshot.paramMap.get('id'),
    //   this.activatedRoute.snapshot.paramMap.get('fecha_venta'),
    //   this.activatedRoute.snapshot.paramMap.get('nit'),
    //   this.activatedRoute.snapshot.paramMap.get('nombre_cliente'),
    //   this.activatedRoute.snapshot.paramMap.get('descripcion'),
    //   this.activatedRoute.snapshot.paramMap.get('id_producto'),
    //   this.activatedRoute.snapshot.paramMap.get('cant'),
    //   +this.activatedRoute.snapshot.paramMap.get('valor'));
    console.log("Esta es la venta que me llega: ", this.id);
    this.getPageInfo();
    this.getClient();
  }

  private getClient() {
    this.client = JSON.parse(sessionStorage.getItem('cliente'));
  }

  private getPageInfo() {
    this.dashboardService.detalleCompraPageInfo(this.id).subscribe(res => {
      console.log("Esto es lo que me retorna el servicio: ", res);

      if (res.code = 200) {
        this.numeroLinea = res.producto.id_linea;
        this.nombreLinea = res.nombreLinea;
        this.numeroGrupo = res.producto.id_grupo;
        this.nombreGrupo = res.nombreGrupo;
        this.nombreMarca = res.producto.nombre_marca;
        this.refVernier = res.producto.ref_vernier;
        this.refProveedor = res.producto.ref_proveedor;
        this.serial = res.producto.serial;
        this.precioProducto = res.producto.precio;
        this.description = res.producto.descripcion;
        this.nombreCliente = res.producto.nombre_cliente;
        this.cant = res.producto.cant;
        this.fecha_venta = res.producto.fecha_venta;
        this.valor = res.producto.valor;
        this.items_recomendados = JSON.parse(sessionStorage.getItem('items_recomendados'));
        this.url_image = this.url_image+res.producto.img_name+'.png';
        console.log("Estos son los items recomendados desde detalle compra: ", this.items_recomendados);
        this.spinner.hide();
      } else {
        this.spinner.hide();
        console.log(res.message);
      }
    }, error => {
      this.spinner.hide();
      console.log('Ups, ha habidio un error: ', error);
    });
  }

  toggleFavorite(item: any) {
    let data = {
      cliente: this.client.id,
      ref_product: item.id
    };
    this.dashboardService.setFavoriteItem(data).subscribe(res => {
      this.items_recomendados = res.new_items;
      sessionStorage.setItem('items_recomendados', JSON.stringify(this.items_recomendados));

    }, error => {
        console.log('Ups, ha habido un error: ', error); 
    });
  }

}
