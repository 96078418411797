<div class="card-body">
    <div class="row">
        <div class="col-12 col-md-8">
            <form #searchForm="ngForm" (ngSubmit)="onSubmitSearch(searchForm)" class=" col-12 col-md-5 input-group rounded margin-filter">
                <input [(ngModel)]="search_input" type="search" [formControl]="myControl" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                <button class="input-group-text border-0" id="search-addon" type="submit">
                            <i class="fas fa-search"></i>
                </button>
            </form>
        </div>
        <div class="col-12 col-md-4">
            Elementos por página:
            <select (change)="PageSizeChange($event)">
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
        </div>
        <div class="col-12 col-md-12">
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Marca</th>
                        <th scope="col">Referencia</th>
                        <th scope="col">Referencia Proveedor</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Precio</th>

                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let product of products| paginate : {
                        itemsPerPage: pageSize,
                        currentPage: page,
                        totalItems: count
                      };" class="item__recomendado" [routerLink]="['/home/detalle-producto', product.referencia,true]" routerDirection="forward">
                        <td>
                            {{product.marca}}</td>
                        <td>{{product.referencia}}</td>
                        <td>{{product.ref_proveedor }}</td>
                        <td>{{product.descripcion}}</td>
                        <td>$ {{product.precio}}</td>
                    </tr>
                </tbody>



            </table>
            <div class="col-md-12">
                <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" responsive="true" (pageChange)="pageChange($event)"></pagination-controls>
            </div>

        </div>
    </div>

</div>

