<div class="content d-flex justify-content-center ">

    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <img class="image_logo" src="../assets/img/searchPage/logo_big.svg ">
                <h4>Ups!, Algo ha salido mal. </h4>


            </div>
        </div>
    </div>
    <footer class="footer">
        <p class="my-auto footer__settings">
            <u>Términos y condiciones</u> - Política de manejo de Datos - Andre Laurent 2020 © All rights reserved
        </p>
    </footer>
    <div>