import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from '../global';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public url: string;
  public identity: any;
  public token: any;

  constructor(
    public _http: HttpClient, 
  ) {
    this.url = global.url;
  }

  public register(user: User): Observable<any> {
    let json = JSON.stringify(user);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.post(this.url + 'register', params, { headers: headers });
  }

  public signup(user, getToken = null): Observable<any> { 

    if (getToken != null) {
      user.getToken = 'true';
    }

    let json = JSON.stringify(user);
    let params = "json=" + json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(this.url + 'login', params, { headers: headers });
  }

  public getIdentity() {
    // let identity = JSON.parse(localStorage.getItem('identity'));
    let identity = JSON.parse(localStorage.getItem('identity')) ? JSON.parse(localStorage.getItem('identity')) : JSON.parse(sessionStorage.getItem('identity'));

    if (identity && identity != 'undefined') {
      this.identity = identity;
    } else {
      this.identity = null;
    }

    return this.identity;
  }

  public getClient() {
    let client = JSON.parse(sessionStorage.getItem('cliente'));
    if (client && client != 'undefined') {
      return true;
    } else {
      return false;
    }
  }

  public getToken() {
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');

    if (token && token != 'undefined') {
      this.token = token;
    } else {
      this.token = null;
    }

    return this.token;
  }

  public getAllUsers(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.get(this.url + 'user/getAllUsers', { headers: headers });
  }

  public updateUser(user: User): Observable<any> {
    let json = JSON.stringify(user);
    let params = "json=" + json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.put(this.url + 'user/updateUser/' + user.id, params, { headers: headers });
  }

  public deleteUser(id: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'user/deleteUser/' + id, { headers: headers });
  }
}
