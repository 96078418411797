import { Component, OnInit,ViewChild,Output,EventEmitter } from '@angular/core';
import { IDropdownSettings, MultiSelectComponent } from 'ng-multiselect-dropdown';
import { InventarioService } from 'src/app/services/Inventory/inventario.service';
import { FormControl, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Register } from 'src/app/models/register';
@Component({
  selector: 'app-multi',
  templateUrl: './multi.component.html',
  styleUrls: ['./multi.component.scss']
})
export class MultiComponent implements OnInit {
  @ViewChild(MultiSelectComponent) multiselect:MultiSelectComponent;
  @Output() selected: EventEmitter<any[]> = new EventEmitter();
  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings = {};
  public secondSpinLoader: boolean = false;
  public register:Register;
  public search:string;
  public page = 1;
  public count = 0;
  public pageSize = 3;
  public pageSizes = [3, 5, 10];
  public search_input:string;
  public myControl = new FormControl();
  
  public options: NgbModalOptions;
  constructor(private _inventarioService: InventarioService,private fb: FormBuilder,private modal: NgbModal){

    }
  ngOnInit() {
    this.dropdownSettings = {
      noDataAvailablePlaceholderText:"No hay datos",
      singleSelection: false,
      defaultOpen: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
   
    
  }
  onItemSelect(item: any) {
    this.selected.emit(this.selectedItems);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    this.selectedItems = items;
    this.selected.emit(this.selectedItems);
    console.log(this.selectedItems);
  }
  onFilterChange(search:any){
    this.search = search;
    this.getProducts();
    console.log(this.dropdownList);
  }
  getProducts(){
    let request = {
      search:this.search_input,
      page:this.page,
      pageSize:this.pageSize
    };
    this._inventarioService.getProduct(request).subscribe(
      response => {
        this.dropdownList = response.products
        console.log(this.dropdownList);
        console.log(this.multiselect);
        this.multiselect._settings.defaultOpen = true;
      },
      error => {
        console.log(error);
      }
    );
  }
  public onSubmitRegister(form: any) {
   console.log(form.value)

  }
  
}
