import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { global } from '../global';
@Injectable({
  providedIn: 'root'
})
export class InventarioService {
  public url: string;
  constructor(
    private http: HttpClient
  ) {
    this.url = global.url;
   }
   public getProducts(data: any): Observable<any> {
    let json = JSON.stringify(data);
    let params = 'json=' + json;
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(this.url + 'getProducts', params, { headers: headers });

  }
  public getProduct(data: any): Observable<any> {
    let json = JSON.stringify(data);
    let params = 'json=' + json;
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(this.url + 'getProduct', params, { headers: headers });

  }
}
