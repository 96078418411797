import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/User/user.service';

@Injectable({
  providedIn: 'root'
})
export class IsClientGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService
  ) {
    
  }

  async canActivate() {
    const autenticationValidator = await this.userService.getClient();
    if (autenticationValidator) {
      return true;
    } else {
      this.router.navigate(['search-page']);
    }
  }
  
}
