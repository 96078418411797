import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/User/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  // public id: number,
  // public name: string,
  // public surname: string,
  // public role_id: number,
  // public status: number,
  // public email: string,
  // public password: string,
  // public image: string

  public users: User[];
  public message: string;
  public status: string;
  public userToEdit: User;
  public user: User;


  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private modal: NgbModal
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getAllUsers();
  }

  private getAllUsers() {
    this.userService.getAllUsers().subscribe(res => {
      // console.log("Esto es lo que trae la rta: ", res);
      if (res.code = 200) {
        this.status = res.status;
        this.users = res.users;
        this.spinner.hide();
      } else if (res.code == 400) {
        this.status = res.status;
        this.message = res.message;
        this.spinner.hide();
      }
    }, error => {
      console.log("Ups, ha ocurrido un error: ", error);
      this.spinner.hide();
    });
  }

  public updateUser(user: User) {
    console.log("Este es el user: ", user);
  }

  public openModal(contenido, user) {
    this.user = user;
    console.log("User in modal: ", user);
    const options: NgbModalOptions = {
      size: 'lg',
      //centered: true,
      scrollable: true,
      windowClass: 'modal__class'
    };
    this.modal.open(contenido, options).result.then((result) => {
      console.log("Esto es result: ", result);
      //console.log("Este es usuario updated: ", this.userToEdit);
      // if (result == "Save Click") {
      //   console.log("Entramos a save user");
      //   this.userService.updateUser(this.userToEdit).subscribe(res => {
      //     console.log("Esto es res: ", res);
      //     if (res.code == 200) {
      //       this.spinner.show();
      //       this.getAllUsers();
      //     }
      //   }, error => {
      //       console.log("Ups!, ha habido un error: ", error);
      //   });
      // } else if (result == "Cancel Click") {
      //   this.spinner.show();
      //   this.getAllUsers();
      //   console.log("Entramos a cancel click");
      // }

      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //console.log("Esto es reason: ", reason);
      // if (reason == "Dismissed Click") {
      //   console.log("entramos a dismissed");
      // } else if (reason == "Close Click") {
      //   console.log("Entramos a close click");
      // }
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  public onSubmitUser(form) {
    this.spinner.show();
    this.userToEdit = form.value;
    this.userToEdit.id = this.user.id;
    this.modal.dismissAll();
    console.log("Esto es form: ", form.value);
    console.log("Esto es form: ", this.userToEdit);
    this.userService.updateUser(this.userToEdit).subscribe(res => {
      console.log("Esto es res: ", res);
      if (res.code == 200) {
        this.getAllUsers();
      } else {
        console.log("Entramos al else: ", res);
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      console.log("Ups!, ha habido un error: ", error);
    });
  }

  public deleteUser(user: User) {
    this.spinner.show();
    this.userService.deleteUser(user.id).subscribe(res => {
      console.log("Esta es la rta: ", res);
      if (res.code == 200) {
        this.getAllUsers();
      } else {
        console.log(res.message);
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      console.log("Ups!, ha habido un error: ", error);
    })
  }
}
