<!--Page content-->
<div class="said">
    <div class="row no-gutters general__row">

        <div class="col-sm-6 no-gutters first__column">
            <div class="leftside d-flex justify-content-center align-items-center">
            </div>
            <footer class="footer text-center py-3">
                <p style="font-size: 10px;">
                    <u>Términos y condiciones</u> - Política de manejo de Datos - Andre Laurent 2020 © All rights reserved
                </p>
            </footer>
        </div>

        <!-- DERECHA -->

        <div class="col-sm-6 no-gutters">
            <section *ngIf="bandera" class="form-elegant section-login">

                <!--Form without header-->
                <div class="card">
                    <div style="margin: auto;" class="card-body mx-4 text-center">
                        <img class="image_logo" src="../assets/img/introPage/logo.svg">

                        <!--Header-->
                        <div class="text-center" style="margin-top: 7%;">
                            <span style="color: rgb(165, 162, 162)" class=" dark-grey-text mb-5 ">Iniciar Sesión</span>
                        </div>

                        <div class="container mt-2 font-small">
                            <!-- EMPIEZA EL FORMULARIO -->
                            <form #loginForm="ngForm" (ngSubmit)="onSubmitLogin(loginForm)">
                                <!-- Nombre de usuario -->
                                <div class="md-form mt-3 " class="form-group">
                                    <div class="md-form ">

                                        <mat-form-field class="mt-auto" appearance="legacy">
                                            <input type="email" name="email" placeholder="Nombre de Usuario" #email="ngModel" [(ngModel)]="user.email" matInput>
                                        </mat-form-field>

                                        <!--small style="color: #EB9873;" *ngIf="email.touched" class="invalid-feedback d-block">
                                            El Email no es válido.
                                        </small-->
                                    </div>
                                </div>

                                <div class="form-group">
                                    <mat-form-field appearance="legacy">
                                        <input type="password" name="password" placeholder="Contraseña" #password="ngModel" [(ngModel)]="user.password" matInput>
                                    </mat-form-field>
                                    <!--small style="color: #EB9873;" *ngIf="password.touched" class="invalid-feedback d-block">
                                        Contraseña no válida.
                                    </small-->
                                </div>

                                <div class="md-form pb-3 form-group">
                                    <div class="d-flex justify-content-between bd-highlight mb-1 top_no_cierre_sesion">
                                        <mat-checkbox name="checkbox" #checkbox="ngModel" [(ngModel)]="checkBox" style="color: gray;" class="example-margin">No cerrar Sesión
                                        </mat-checkbox>
                                        <p class="d-flex justify-content-end">
                                            <a href="# " class="ml-1 ">
                                                <u style="color: #EB9873;"><b>Recuperar Contraseña</b></u> </a>
                                        </p>
                                    </div>
                                </div>

                                <div *ngIf="status == 'error'" class="mb-2 alert alert-danger d-blok">
                                    <span *ngIf="message">{{message}}</span>
                                </div>

                                <div class="separator font-small top_ingresa"> Iniciar Sesión </div>
                                <div class="d-flex justify-content-between bd-highlight mb-1 my-2">
                                    <button id="font-button" type="submit" class="btn w-100 btn__acceder">Acceder</button>
                                    <!-- <button id="font-button" type="submit" class="btn w-50 ml-2 new_button_google">
                                        <i class="icon-smaller fab fa-google-plus-g" id="font-button"></i>
                                        Google
                                    </button> -->
                                </div>
                            </form>
                            <!-- TERMINA EL FORMULARIO -->

                            <!--p class="font-small d-flex justify-content-center mt-5">No tienes cuenta,
                                <a (click)="onChangeToRegisterView()" class="ml-1">
                                    <span style="color: #EB9873;">REGÍSTRATE</span> </a>
                            </p-->
                        </div>
                        <!--Body-->


                    </div>
                </div>
                <!--/Form without header-->

            </section>

            <!-- REGISTRO ---------------------------------------------------------------- -->
            <!-- ------------------------------------------------------------------------- -->
            <!-- ------------------------------------------------------------------------- -->
            <!-- ------------------------------------------------------------------------- -->
            <section *ngIf="!bandera" class="form-elegant section-login">

                <!--Form without header-->
                <div class="card">
                    <div style="margin: auto;" class="card-body mx-4 text-center">
                        <img class="image_logo" src="../assets/img/introPage/logo.svg">

                        <!--Header-->
                        <div class="text-center" style="margin-top: 7%;">
                            <span style="color: rgb(165, 162, 162)" class=" dark-grey-text mb-5 ">Regístrate</span>
                        </div>

                        <div class="container mt-2 font-small">
                            <!-- EMPIEZA EL FORMULARIO -->
                            <form #registerForm="ngForm" (ngSubmit)="onSubmitRegister(registerForm)">

                                <div class="md-form mt-3 " class="form-group">
                                    <div class="md-form ">

                                        <mat-form-field class="mt-auto" appearance="legacy">
                                            <input type="text" name="name" placeholder="Nombre" #name="ngModel" [(ngModel)]="user.name" pattern="/^[A-Za-z]+$/" required matInput>
                                        </mat-form-field>

                                        <small style="color: #EB9873;" *ngIf="!name.valid && name.touched" class="invalid-feedback d-block">
                                            El nombre no es válido.
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <mat-form-field appearance="legacy">
                                        <input type="text" name="surname" placeholder="Apellidos" #surname="ngModel" [(ngModel)]="user.surname" pattern="[a-zA-Z]+" required matInput>
                                    </mat-form-field>
                                    <small style="color: #EB9873;" *ngIf="!surname.valid && surname.touched" class="invalid-feedback d-block">
                                        Apellidos inválidos.
                                    </small>
                                </div>

                                <div class="form-group">
                                    <mat-form-field appearance="legacy">
                                        <input type="email" name="email" placeholder="Correo electrónico" #email="ngModel" [(ngModel)]="user.email" matInput required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                                    </mat-form-field>
                                    <small style="color: #EB9873;" *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">
                                        Correo electrónico no válido.
                                    </small>
                                </div>

                                <div class="form-group">
                                    <mat-form-field appearance="legacy">
                                        <input type="password" name="password" placeholder="Contraseña" #password="ngModel" [(ngModel)]="user.password" required matInput>
                                    </mat-form-field>
                                    <small style="color: #EB9873;" *ngIf="!password.valid && password.touched" class="invalid-feedback d-block">
                                        Contraseña no válida.
                                    </small>
                                </div>

                                <div *ngIf="status == 'error'" class="mb-2 alert alert-danger d-blok">
                                    El registro no se ha podido completar. <span *ngIf="message">{{message}}</span>
                                </div>

                                <div class="d-flex mt-4 justify-content-between bd-highlight mb-1 my-2">
                                    <button id="font-button" style="background-color: #EB9873;" type="submit" class="btn w-100 mx-auto btn__acceder" [disabled]="registerForm.invalid">Acceder</button>
                                </div>
                            </form>

                            <!-- TERMINA EL FORMULARIO -->

                            <p class="font-small d-flex justify-content-center mt-3">Regresar a,
                                <a (click)="onChangeToLoginView()" class="ml-1">
                                    <span style="color: #EB9873;">LOGIN</span> </a>
                            </p>
                        </div>
                        <!--Body-->


                    </div>
                </div>
                <!--/Form without header-->

            </section>



            <footer class="footer text-center py-3 ">
                <p style="font-size: 12px; ">
                    Síguenos en redes sociales
                    <i style="padding-left:1rem; " class="fab fa-facebook-f "></i>
                    <i style="padding-left:1rem; " class="fab fa-twitter "></i>
                    <i style="padding-left:1rem; " class="fab fa-linkedin-in "></i>
                    <i style="padding-left:1rem; " class="fab fa-instagram "></i>
                </p>
            </footer>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-climbing-dot">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>