import { Component, OnInit } from '@angular/core';
import { Ventas } from 'src/app/models/ventas';
import { NgxSpinnerService } from 'ngx-spinner';
import { global } from 'src/app/services/global';

@Component({
  selector: 'app-lista-productos',
  templateUrl: './lista-productos.component.html',
  styleUrls: ['./lista-productos.component.scss']
})
export class ListaProductosComponent implements OnInit {

  public items_recomendados: Array<Ventas[]>;
  public nombre_Articulo: string;
  public referencia: string;
  public valor: number;
  public url_image: string = global.url_image;

  constructor(
    private spinner: NgxSpinnerService
  ) {
    
  }

  ngOnInit(): void {
    this.spinner.show();
    this.items_recomendados = JSON.parse(sessionStorage.getItem('items_recomendados'));
    this.spinner.hide();
  }

}
