export var global = {
  // url: 'http://andre-laurent.com.devel/api/',

  // Producción
  url: 'https://andrelaurentapi.smartdataautomation.com/public/api/',
  url_image: 'https://andrelaurentapi.smartdataautomation.com/storage/app/images/' ,
  //url_video: 'https://andrelaurentapi.smartdataautomation.com/storage/app/public/videos/',

  // Desarrollo
  //url_image: 'http://localhost/Andre_laurent/andrelaurant_front/src/assets/img',
  url_video: 'http://localhost/Andre_laurent_2/andrelaurant_back/storage/app/public/videos/',
  //url: 'http://localhost/Andre_laurent_2/andrelaurant_back/public/api/',
  
  //DEV
  /* url_image: 'http://127.0.0.1:8000/storage/app/images/',
  url: 'http://127.0.0.1:8000/api/', */
 


  // url_crm: 'http://52.4.88.69/SuiteCRM-7.11.18/Api/',
  //https://andrelaurent_crm.smartdataautomation.com/
  url_crm: 'https://andrelaurent_crm.smartdataautomation.com/Api/'
} 