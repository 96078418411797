<div class="content w-100 h-100 p-0 m-0">
    <div class="row h-100 w-100 p-0 m-0" id="general__row">
        <div class="col-12 p-0 m-0" id="general__col">
            <nav class="navbar p-0 py-3 align-middle">
                <a class="navbar-brand p-0 w-100 align-middle">
                    <img class="align-middle" [routerLink]="['/home/dashboard']" routerDirection="forward" src="assets/img/detalleProductoPage/back_arrow.svg" style="cursor: pointer;">
                    <span class="align-middle ml-3 w-100" style="color: #191918;
                        font-family: 'Source Sans Pro', sans-serif;
                        font-weight: 400; font-size: 1.4rem;">Gestión de usuarios</span>
                    <div>
                        <h1 style="display: block; margin: 0;"></h1>
                        <h1 style="display: block; margin: 0;"></h1>
                    </div>
                </a>
            </nav>
            <div *ngIf="status == 'error'" class="mb-2 alert alert-danger d-blok">
                <span *ngIf="message">{{message}}</span>
            </div>
            <div id="no-more-tables" class="table-responsive">
                <table class="table  table-striped text-center mt-2">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Apellidos</th>
                            <th scope="col">Role</th>
                            <th scope="col">Status</th>
                            <th scope="col">Email</th>
                            <th scope="col">Editar</th>
                            <th scope="col">Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of users">
                            <td data-title="Nombre">{{ user.name }}</td>
                            <td data-title="Apellidos">{{ user.surname }}</td>
                            <td data-title="Role">{{ user.role_id }}</td>
                            <td data-title="Status">{{ user.status }}</td>
                            <td class="email__field" data-title="Email">{{ user.email }}</td>
                            <td data-title="Editar">
                                <!-- <i (click)="updateUser(user)" class="fas fa-user-edit"></i> -->
                                <i (click)="openModal(contenido, user)" class="fas fa-user-edit" style="cursor: pointer;"></i>
                            </td>
                            <td data-title="Eliminar" (click)="deleteUser(user)">
                                <i class="fas fa-trash-alt"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-climbing-dot">
    <p style="font-size: 20px; color: white">Cargando...</p>
</ngx-spinner>

<ng-template #contenido let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Ajuste de usuario</h4>
        <button style="outline: none !important;" class="close" aria-hidden="close" type="button" (click)="d('Dismissed Click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form #settingsForm="ngForm" (ngSubmit)="onSubmitUser(settingsForm)">
            <div class="form-group">
                <label for="name">Nombre</label>
                <input type="text" name="name" class="form-control" [ngModel]="user.name">
            </div>
            <div class="form-group">
                <label for="surname">Apellidos</label>
                <input type="text" name="surname" class="form-control" [ngModel]="user.surname">
            </div>
            <div class="d-flex justify-content-between">
                <div class="form-group flex__inputs pr-2">
                    <label for="role_id">Role</label>
                    <input type="number" name="role_id" class="form-control" [ngModel]="user.role_id">
                </div>
                <div class="form-group flex__inputs">
                    <label for="status">Status</label>
                    <input type="number" name="status" class="form-control" [ngModel]="user.status">
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning button__modal__class" type="button" (click)="c('Cancel Click')">Cancelar</button>
                <button class="btn btn-success button__modal__class" type="submit">Guardar</button>
            </div>
        </form>
    </div>

</ng-template>
<!-- <ng-template #contenido let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Titulo modal</h4>
        <button style="outline: none !important;" class="close" aria-hidden="close" type="button" (click)="d('Dismissed Click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Este es el contenido de este modal.
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" type="button" (click)="c('Close Click')">Ok!</button>
    </div>
</ng-template> -->