<div class="content d-flex justify-content-center " >
    <!-- <video class="video-bg" autoplay playsinline loop src="../../../assets/img/video.mov" type="video/mov"></video> -->
    <!-- <video class="video-bg" loop [muted]="true" autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" src="{{url_videos + videoAct?.name_video}}" onError="this.src='../../../assets/img/new_video.mov'" type="video/mov">
    </video> -->

    <video class="video-bg" loop [muted]="true" autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" src="{{url_videos}}" onError="this.src='../../../assets/img/new_video.mov'" type="video/mov">
    </video>

    <div class="container">
        <div class="row">

            <div class="col-7 col-sm-4  col-md-4 align_nav  d-lg-inline-flex ">
                <nav class="navbar navbar-expand-lg navbar-light bg-light nav_background">
                    <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span class="navbar-toggler-icon "></span>
                    </button>

                    <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                        <ul class="navbar-nav">
                            <li class="nav-item active">
                                <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Opciones</a>
                                <div class="dropdown-menu " aria-labelledby="dropdownMenuLink">
                                    <a class="dropdown-item" [routerLink]="['/home/user-register']"><i class="fas fa-user-edit"></i>Registro de usuario</a>
                                    <a class="dropdown-item" [routerLink]="['/home/data-import']"><i class="fas fa-chart-bar"></i>Importar datos</a>
                                    <a class="dropdown-item" [routerLink]="['/home/user-list']"><i class="fas fa-users"></i>Gestión de usuario</a>
                                    <a class="dropdown-item" [routerLink]="['/home/productos-inventario']"><i class="fas fa-clipboard-list"></i>Inventario</a>
                                </div>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link dropdown-toggle dropdown__navbar border-left" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{identity.name + ' ' + identity.surname}}
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href="#">Buscar Cliente</a>
                                    <!-- <a class="dropdown-item" href="#">Another action</a> -->
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" (click)="logout()" routerDirection="forward">Cerrar Sesión</a>
                                </div>
                            </li>
                            <li class="nav-item d-none d-lg-block">
                                <img style="background-color: white !important; width: 40px;" class="rounded-circle mr-2" src="assets/img/avatar.png">
                            </li>
                        </ul>

                    </div>
                </nav>
            </div>

        </div>



        <div class="row general__row">
            <div class="col-12 text-center">
                <img class="image_logo" src="../assets/img/searchPage/logo_big.svg ">
                <!-- <h4 class="my-4 pb-4 xs__device" style="letter-spacing: 1px;">Introducir Nombre del Cliente</h4> -->

                <div class="col-sm-10 mx-auto input-group navbar__container" style="margin-top: 5%;">
                    <nav class="p-0 m-0  w-100 navbar justify-content-between">

                        <form #searchForm="ngForm" (ngSubmit)="onSubmitSearch(searchForm, askModal)" class="h-100" style="display: flex; width: 100%; ">

                            <input [(ngModel)]="variable" [formControl]="myControl" class="form-control h-100 pl-4 text-left" matInput [matAutocomplete]="auto" type="search" placeholder="Nombre del cliente">

                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let cliente of filteredOptions | async" [value]="cliente">
                                    {{cliente.nombre}}
                                </mat-option>
                            </mat-autocomplete>

                            <button class="btn my-2 my-sm-0 btn__search" type="submit">
                                <img class="mx-auto search_image" src="../../../assets/img/searchPage/icon_search.svg">
                            </button>
                        </form>
                    </nav>

                    <!-- <div *ngIf="status == 'error'" class="mt-4 w-100 mx-5 alert alert-danger d-blok">
                        <span>Ese cliente no existe.</span>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <footer class="footer">
        <p class="my-auto footer__settings">
            <u>Términos y condiciones</u> - Política de manejo de Datos - Andre Laurent 2020 © All rights reserved
        </p>
    </footer>
    <div>


        <ng-template #askModal let-c="close" let-d="dismiss" let-modal>
            <div *ngIf="!spinLoader" class="modal-header">
                <h4 class="modal-title">Ups! ese Usuario no existe, te gustaría buscar en el crm de Andre Laurent? </h4>
                <button style="outline: none !important;" class="close" aria-hidden="close" type="button" (click)="d('Dismissed Click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div *ngIf="spinLoader" class="modal-spinner">
                <i class="fa fa-spinner fa-spin mr-2"> </i>
                <!-- {{spinLoader? 'Buscando..' : 'Ok'}} -->
            </div>

            <div class="modal-footer">
                <button class="btn button__modal__class" type="button" (click)="c('Cancel Click')">Cancelar</button>
                <button class="btn button__modal__class" (click)="successBtn()">{{spinLoader? 'Buscando..' : 'Buscar'}}</button>
                <!-- <button class="btn button__modal__class" (click)="register()">{{spinLoader? 'Buscando..' : 'Buscar'}}</button> -->
            </div>
        </ng-template>

        <ng-template #creactionSatisfactoria let-c="close" let-d="dismiss" let-modal>
            <div class="modal__content">
                <i class="far fa-check-circle"></i>
                <h4 class="modal-title">Cliente registrado en el CRM satisfactoriamente.</h4>
            </div>
        </ng-template>

        <ng-template #wargingModal let-c="close" let-d="dismiss" let-modal>
            <div class="modal__content">
                <i class="fas fa-exclamation-triangle"></i>
                <h4 class="modal-title">Ups! ha habido un error, inténtalo de nuevo</h4>
            </div>
            <div class="modal-footer">
                <button class="btn button__modal__class" type="button" (click)="c('Cancel Click')">Cancelar</button>
                <button class="btn button__modal__class" (click)="closeAllModals()">Seguir Buscando..</button>
            </div>
        </ng-template>

        <ng-template #askRegisterContact let-c="close" let-d="dismiss" let-modal>
            <div *ngIf="!flagRegisterContact" class="modal__content">
                <i class="fas fa-exclamation-triangle"></i>
                <h4 class="modal-title">Ese usuario no se encuentra en el CRM quisieras agregarlo?</h4>
            </div>
            <div class="modal-footer">
                <button class="btn button__modal__class" type="button" (click)="closeAllModals()">Cancelar</button>
                <button (click)="register()" class="btn button__modal__class">Agregar</button>
            </div>
        </ng-template>

        <ng-template #registerContact let-c="close" let-d="dismiss" let-modal>
            <div class="table-responsive">
                <table class="table">
                    <div class="modal__register">
                        <form id="new__form" #formDirective="ngForm" [formGroup]="registerForm" (ngSubmit)="onSubmitRegister(registerForm, formDirective)">
                            <div class="form-group">
                                <label for="exampleFormControlInput1">Nombre(s)</label>
                                <input formControlName="first_name" type="email" class="form-control" id="exampleFormControlInput1" placeholder="Nombre(s)">
                            </div>
                            <div class="form-group">
                                <label for="exampleFormControlInput2">Apellido(s)</label>
                                <input formControlName="last_name" type="email" class="form-control" id="exampleFormControlInput2" placeholder="Apellido(s)">
                            </div>

                            <div class="form-group">
                                <label for="exampleFormControlInput3">Correo electrónico</label>
                                <input formControlName="email" type="email" class="form-control" id="exampleFormControlInput3" placeholder="nombre@example.com">
                            </div>
                            <div class="form-group">
                                <label for="exampleFormControlInput3">Teléfono</label>
                                <input formControlName="telefono" type="email" class="form-control" id="exampleFormControlInput4" placeholder="Télefono">
                            </div>
                            <div class="form-group">
                                <label for="exampleFormControlInput3">Productos solicitados</label>
                                <app-multi (selected)="onItemSelect($event)"></app-multi>
                            </div>
                            <div class="form-group">
                                <label for="exampleFormControlTextarea1">Breve descripción del cliente</label>
                                <textarea formControlName="user_description" class="form-control" id="exampleFormControlTextarea5" rows="3"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="exampleFormControlInput5">Título de Reporte</label>
                                <input formControlName="tag_description" type="email" class="form-control" id="exampleFormControlInput5" placeholder="Breve título de la observación">
                            </div>
                            <div class="form-group">
                                <label for="exampleFormControlTextarea6">Observación</label>
                                <textarea formControlName="event_description" class="form-control" id="exampleFormControlTextarea6" rows="3"></textarea>
                            </div>

                            <div class="modal-footer">
                                <button class="btn button__modal__class" type="button" (click)="c('Cancel Click')">Regresar</button>
                                <button class="btn button__modal__class" type="submit"><i class="fa fa-spinner fa-spin mr-2" *ngIf="secondSpinLoader"> </i>
                                    {{secondSpinLoader? 'Creando..' : 'Crear'}}</button>
                            </div>
                        </form>
                    </div>

                </table>
            </div>


        </ng-template>

        <ng-template #listContacts let-c="close" let-d="dismiss" let-modal>
            <h4 class="modal-title my-3 new__title">Usuario(s) encontrados en el CRM</h4>
            <div id="no-more-tables" class="table-responsive" style="height: 50vh;">
                <table class="h-100 table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Descripción</th>
                            <th>Ver Historial</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of clientsFromCrm">
                            <td data-title="Nombre">{{item.first_name}}</td>
                            <td data-title="Apellido">{{item.last_name}}</td>
                            <td data-title="Descripción">{{item.description? item.description : 'No hay información referente a este usuario.'}}</td>
                            <td data-title="Historial">
                                <a (click)="contactHistory(item)" style="cursor: pointer;">
                                    <img src="assets/img/dashboardPage/view.svg">
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button class="btn button__modal__class" (click)="closeAllModals()"><i class="fa fa-spinner fa-spin mr-2" *ngIf="secondSpinLoader"> </i>
                    {{secondSpinLoader? 'Buscando..' : 'Nueva búsqueda'}}</button>
                <button (click)="register()" class="btn button__modal__class">Crear nuevo usuario</button>
            </div>
        </ng-template>

        <ng-template #contactHistroy let-c="close" let-d="dismiss" let-modal>
            <h4 class="modal-title my-3 new__title">Historial de cliente {{ historyContact.first_name }} {{historyContact.last_name}}</h4>
            <div id="no-more-tables" class="table-responsive" style="height: 50vh;">
                <table class="h-100 table table-striped table-hover">
                    <thead>
                        <tr>
                            <!-- <th>Reporte</th> -->
                            <th>Fecha y Hora</th>
                            <th>Descripción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of historyRegister">
                            <!-- <td data-title="Reporte">{{item.name}}</td> -->
                            <td data-title="Fecha y Hora">{{item.date_entered}}</td>
                            <td data-title="Descripción">{{item.description}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button class="btn button__modal__class" type="button" (click)="c('Cancel Click')">Regresar</button>
                <button class="btn button__modal__class" (click)="openAddHistory()">Agregar Historia</button>
            </div>
        </ng-template>

        <ng-template #agregarHistoriaContacto let-c="close" let-d="dismiss" let-modal>
            <div class="table-responsive">
                <table class="table">
                    <div class="modal__register">
                        <form id="new__form" #formDirective="ngForm" [formGroup]="addHistoryContact" (ngSubmit)="addHistory(addHistoryContact, formDirective)">
                            <div class="form-group">
                                <label for="exampleFormControlInput4">Título de Reporte</label>
                                <input formControlName="id" type="text" class="form-control" id="exampleFormControlInput4" placeholder="Breve título del a observación">
                            </div>
                            <div class="form-group">
                                <label for="exampleFormControlTextarea2">Observación</label>
                                <textarea formControlName="event_description" class="form-control" id="exampleFormControlTextarea2" rows="3"></textarea>
                            </div>
                            <div class="modal-footer">
                                <button class="btn button__modal__class" type="button" (click)="c('Cancel Click')">Regresar</button>
                                <button class="btn button__modal__class" type="submit"><i class="fa fa-spinner fa-spin mr-2" *ngIf="secondSpinLoader"> </i>
                                    {{secondSpinLoader? 'Agregando..' : 'Agregar'}}</button>
                            </div>
                        </form>
                    </div>

                </table>
            </div>


        </ng-template>