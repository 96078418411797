<div class="box font-small pt-sm-1 pt-lg-2">
    <div class="row mt-5 w-100 h-100 general__row">
        <div class="col-lg-9 w-100 h-100">
            <!-- EMPIEZA EL FORMULARIO -->
            <form #registerForm="ngForm" (ngSubmit)="onSubmitRegister(registerForm)">

                <div class="md-form mt-3 " class="form-group">
                    <div class="md-form ">

                        <mat-form-field class="mt-auto" appearance="legacy">
                            <input type="text" name="name" placeholder="Nombre" #name="ngModel" [(ngModel)]="user.name" required matInput>
                        </mat-form-field>

                        <small *ngIf="!name.valid && name.touched" class="invalid-feedback d-block">
                    El nombre no es válido.
                </small>
                    </div>
                </div>

                <div class="form-group">
                    <mat-form-field appearance="legacy">
                        <input type="text" name="surname" placeholder="Apellidos" #surname="ngModel" [(ngModel)]="user.surname" required matInput>
                    </mat-form-field>
                    <small *ngIf="!surname.valid && surname.touched" class="invalid-feedback d-block">
                Apellidos inválidos.
            </small>
                </div>

                <div class="form-group">
                    <mat-form-field appearance="legacy">
                        <input type="email" name="email" placeholder="Correo electrónico" #email="ngModel" [(ngModel)]="user.email" matInput required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                    </mat-form-field>
                    <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">
                Correo electrónico no válido.
            </small>
                </div>

                <div class="form-group">
                    <mat-form-field appearance="legacy">
                        <input type="password" name="password" placeholder="Contraseña" #password="ngModel" [(ngModel)]="user.password" required matInput>
                    </mat-form-field>
                    <small *ngIf="!password.valid && password.touched" class="invalid-feedback d-block">
                Contraseña no válida.
            </small>
                </div>

                <div *ngIf="status == 'error'" class="mb-2 alert alert-danger d-blok">
                    El registro no se ha podido completar. <span *ngIf="message">{{message}}</span>
                </div>

                <div *ngIf="status == 'success'" class="mb-2 alert alert-success d-blok">
                    Registro completado satisfactoriamente. <span *ngIf="message">{{message}}</span>
                </div>

                <div class="d-flex mt-3 justify-content-between bd-highlight mb-1 my-2">
                    <button type="submit" class="btn w-100 mx-auto font-button" [disabled]="registerForm.invalid">Registrar usuario</button>
                </div>
            </form>
            <div class="d-flex mt-4 justify-content-between bd-highlight mb-1 my-2">
                <button (click)="goToDashboard()" class="btn w-100 mx-auto font-button">Regresar a Dashboard</button>
            </div>
        </div>
    </div>
</div>
<!--Body-->

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-climbing-dot">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>