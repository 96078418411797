<div class="said">
    <div class="row no-gutters h-100" style="background-color: #FBF6F2;">
        <div class="col-sm-12 col-lg-9 general__col" style="background-color: transparent;">
            <div class="row m-0 p-0 pt-3">
                <div class="col-sm-8 m-0 p-0 pr-2">
                    <div class="row d-flex justify-content-between order-xs-3 order-1" id="row__cards">
                        <div class="col-sm-4 card__xs">
                            <div class="card text-center">
                                <div class="card-body px-0 justify-content-center align-items-center">
                                    <h4 class="card-title pt-1"><a>{{ comprasAno }}</a></h4>
                                    <!-- Text -->
                                    <h5 style="color: #4695D9;" class="card__text mx-auto pt-3 text-uppercase ">Compras totales del cliente
                                    </h5>
                                </div>
                                <!-- Card footer -->
                                <div class="card-footer px-0 text-muted">
                                    <span class="text-success mx-auto"><i class="fa fa-arrow-up "></i> 13%
                                        ASCENDENTE</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 card__xs">
                            <div class="card text-center">
                                <div class="card-body px-0 justify-content-center align-items-center">
                                    <!-- Title -->
                                    <h4 class="card-title pt-1"><a>{{ totalCompras }}</a></h4>
                                    <!-- Text -->
                                    <h5 style="color: #21BB39;" class="card__text pt-3 mx-auto text-uppercase ">Total de compras
                                    </h5>
                                </div>
                                <!-- Card footer -->
                                <div class="card-footer px-0 text-muted">
                                    <span class="text-success mx-auto"><i class="fa fa-arrow-up "></i> 4%
                                        ASCENDENTE</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 card__xs">
                            <div class="card text-center">
                                <div class="card-body px-0 justify-content-center align-items-center">
                                    <!-- Title -->
                                    <h4 class="card-title pt-1"><a>{{ trm_ob}}</a></h4>
                                    <!-- Text -->
                                    <h5 style="color: #0c0c0c;" class="card__text pt-3 mx-auto text-uppercase ">TRM
                                    </h5>
                                </div>
                                <!-- Card footer -->
                                <div class="card-footer px-0 text-muted">
                                    <span class="text-success mx-auto"> {{trm_date | date: 'dd/MM/yyyy'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="table__container order-xs-1 order-2 table-responsive" id="no-more-tables">

                        <table class="text-center table   table-striped  table-hover table-borderless" style="height: 100%;">
                            <thead>
                                <tr>
                                    <th class="text-left" style="font-weight: 400 !important; color: #354052; font-family: 'Source Sans Pro', sans-serif; font-size: 1.2rem;">
                                        Compras
                                    </th>

                                    <th style="color: #7F8FA4;" class="text__in_table" scope="col">Fecha
                                    </th>

                                    <th style="color: #7F8FA4;" class="text__in_table" scope="col">
                                        Vendedor
                                    </th>

                                    <th style="color: #7F8FA4;" class="text__in_table" scope="col">Total
                                    </th>

                                    <th style="color: #7F8FA4;" class="text__in_table" scope="col">Und. facturada</th>

                                    <th style="color: #7F8FA4;" class="text__in_table" scope="col">
                                        Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let venta of ventas">
                                    <td data-title="Compras" class="align-items-start">
                                        <div class="ml-0 text-left" style="display: block;">
                                            <h6 class="m-0 etiqueta__titulo_item align-middle">
                                                {{ venta.descripcion }}</h6>
                                            <h6 class="mt-1 etiqueta__description_item">
                                                #{{ venta.producto_siigo }}
                                            </h6>
                                        </div>
                                    </td>
                                    <td data-title="Fecha" style="white-space: nowrap;">
                                        {{ venta.fecha }}
                                    </td>
                                    <td data-title="Vendedor" style="white-space: nowrap;">{{venta.ref_proveedor}}</td>
                                    <td data-title="Total" [ngStyle]="{'color': venta.cant>0 ? 'green':'red'}">
                                        ${{ venta.venta | number: '1.0-0' }}</td>

                                    <td data-title="Und. facturada">{{ venta.cant }}</td>
                                    <td data-title="Acciones" [routerLink]="['/home/detalle-compra', venta.producto_siigo]" routerDirection="forward">
                                        <a>
                                            <img src="assets/img/dashboardPage/view.svg">
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>

                <div class="col-sm-4 padding__xs1">
                    <div class="container__list">
                        <nav class="px-2 navbar navbar-expand-lg" style="height: 60px;">
                            <a class="navbar-brand recomendados__tag">Recomendados</a>
                            <div class="collapse navbar-collapse w-100" id="navbarNavDropdown">
                                <!-- <ul class="navbar-nav w-100 text-right">
                                    <div class="dropdown w-100">
                                        <button type="button" class="dropdown__settings btn dropdown-toggle" data-toggle="dropdown">
                                            Todos
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href="#">Link 1</a>
                                            <a class="dropdown-item" href="#">Link 2</a>
                                            <a class="dropdown-item" href="#">Link 3</a>
                                        </div>
                                    </div>
                                </ul> -->
                            </div>
                        </nav>

                        <div class="table-responsive" id="list__recomendados">
                            <table class="table table-borderless table-hover" style="border: none;">
                                <tbody>
                                    <tr *ngFor="let item of items_recomendados" class="d-flex align-items-start item__recomendado mt-2">
                                        <th>
                                            <!-- <img style="width: 20%; border-radius: 10px;" src="assets/img/dashboardPage/tshuc.png"> -->
                                            <div class="ml-0 my-auto" style="display: block;">
                                                <h6 [routerLink]="['/home/detalle-producto', item.referencia,false]" routerDirection="forward" class="m-0 etiqueta__titulo_item align-middle">
                                                    {{ item.descripcion }}</h6>
                                                <div class="favorite__container">
                                                    <div class="favorite__container_first">
                                                        <h6 class="mt-2 etiqueta__description_item">{{ item.referencia }}
                                                        </h6>
                                                        <h6 class="m-0 mt-1 align-middle" style="color: #3BD66A; font-size: .8rem;">
                                                            ${{ item.precio | number: '1.0-0' }}</h6>
                                                    </div>
                                                    <!-- <div class="favorite__container_second" (click)="toggleFavorite(item)">
                                                        <i *ngIf="item.favorite == 0" class="far fa-heart"></i>
                                                        <i *ngIf="item.favorite == 1" class="fas fa-heart"></i>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <!-- <div class="my-auto mx-auto" style="display: block;">
    
                                            </div> -->
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="py-2 text-center" style="height: 60px;">
                            <div class="btn w-50 btn__vermas" [routerLink]="['/home/lista-productos']" routerDirection="forward">
                                <span class="" style="font-weight: bold;">Ver Más</span>
                                <i class="fa fa-arrow-right ml-2"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0 p-0 pt-3 row__graficas">
                <div class="col-sm-12 m-0 p-0">
                    <div class="row__graph">
                        <div class="content__grafica w-100 text-center h-100">
                            <!-- <div id="line_chart" class="w-100 h-100" style="background-color: inherit;"></div> -->
                            <div id="chart" class="w-100 pt-2" style="background-color: inherit;">
                                <div id="responsive-chart" class="w-100" style="background-color: inherit;">
                                    <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [dataLabels]="chartOptions.dataLabels" [grid]="chartOptions.grid" [stroke]="chartOptions.stroke" [title]="chartOptions.title" [labels]="chartOptions.labels"
                                        [annotations]="chartOptions.annotations" [responsive]="chartOptions.responsive" [plotOptions]="chartOptions.plotOptions" useGroupingSeparator = "true"></apx-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-sm-4 padding__xs">
                    <div class="row__graph">
                        <div class="content__grafica w-100 text-center h-100">
                            <div id="bar_chart" class="w-100 h-100" style="background-color: inherit;"></div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="col-lg-3 second__col">
            <div class="text-center" style="position: absolute;">
                <img style="height: 20%; width: 97%; border-radius: 0%; margin-top: 3.6%;" src="assets/img/dashboardPage/Clip@2x.png" alt="">
            </div>
            <div id="profile__container" class="row no-gutters px-4 pt-5" style="height: 100%; background-color: white;">
                <div class="col-12">
                    <div class="container__profile ">
                        <div class="text-center">
                            <img class="rounded-circle avatar__image" src="assets/img/avatar.png">
                            <h3 id="first__title" class="title__profile mt-3">{{ client.nombre }}</h3>
                            <!-- <div class="d-flex justify-content-between mt-3"> -->
                            <div style="display: flex; justify-content: space-around; align-items: center; margin-top: 1rem;">
                                <!-- <button class="btn d-block mr-1 first__btn" type="button">
                                    Editar Perfil
                                </button> -->
                                <button *ngIf="comprasAno > 1" type="button" class="btn d-block second__btn">
                                    <img height="25" width="25" src="assets/img/dashboardPage/Grupo2209.svg" alt="">
                                    <span class="ml-2">Cliente Gold</span>
                                </button>
                            </div>
                            <hr class="mt-2">
                            <h4 class="tag__title mt-2 text-left">Documento:</h4>
                            <h5 class="tag__description mt-1 text-left">{{ client.nit }}</h5>
                            <h4 class="tag__title mt-2 text-left">Teléfono:</h4>
                            <h5 class="tag__description mt-1 text-left">{{ client.telefono }}</h5>
                            <h4 class="tag__title mt-2 text-left">Correo:</h4>
                            <h5 class="tag__description mt-1 text-left">{{ client.correo }}</h5>
                            <h4 class="tag__title mt-2 text-left">Dirección:</h4>
                            <h5 class="tag__description mt-1 text-left">{{ client.direccion }}</h5>
                            <div *ngIf="birthday">
                                <h4 class="tag__title mt-2 text-left">Fecha de cumpleaños:</h4>
                                <h5 class="tag__description mt-1 text-left">{{ birthday }}</h5>
                            </div>

                            <div class="container__dates" *ngIf="itemsDates.length > 0">
                                <nav class="px-2 navbar navbar-expand-lg" style="height: 60px;">
                                    <a class="navbar-brand recomendados__tag">Fechas Especiales</a>
                                    <div class="collapse navbar-collapse w-100" id="navbarNavDropdown">
                                        <!-- <ul class="navbar-nav w-100 text-right">
                                    <div class="dropdown w-100">
                                        <button type="button" class="dropdown__settings btn dropdown-toggle" data-toggle="dropdown">
                                            Todos
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href="#">Link 1</a>
                                            <a class="dropdown-item" href="#">Link 2</a>
                                            <a class="dropdown-item" href="#">Link 3</a>
                                        </div>
                                    </div>
                                </ul> -->
                                    </div>
                                </nav>

                                <div class="table-responsive" id="list__recomendados">
                                    <table class="table table-borderless table-hover" style="border: none;">
                                        <tbody>
                                            <tr *ngFor="let item of itemsDates" class="d-flex align-items-start item__recomendado mt-2">

                                                <th>
                                                    <!-- <img style="width: 20%; border-radius: 10px;" src="assets/img/dashboardPage/tshuc.png"> -->
                                                    <div class="ml-0 my-auto" style="display: block;">
                                                        <h6 class="m-0 etiqueta__titulo_item align-middle">
                                                            {{ item.title }}</h6>
                                                        <div class="favorite__container">
                                                            <div class="container_dates">
                                                                <h6 class="mt-2 etiqueta__description_item">{{ item.fecha }}
                                                                </h6>

                                                            </div>
                                                            <!-- <div class="favorite__container_second" (click)="toggleFavorite(item)">
                                                        <i *ngIf="item.favorite == 0" class="far fa-heart"></i>
                                                        <i *ngIf="item.favorite == 1" class="fas fa-heart"></i>
                                                    </div> -->
                                                        </div>
                                                    </div>
                                                    <!-- <div class="my-auto mx-auto" style="display: block;">
    
                                            </div> -->
                                                </th>
                                                <button type="button" id="btn_delete_date" class="close d-flex align-items-end " data-dismiss="modal" aria-label="Close" (click)=openModalDeleteDate(askModal,item.id,item.title,item.fecha)>
                                                    <span aria-hidden="true">&times;</span>
                                                  </button>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div>
                                <hr class="">
                                <h4 class="tag__title text-left">Clientes relacionados:</h4>
                                <h5 *ngIf="clientesRelacionados.length == 0" class="tag__description mt-1 text-left">No hay ningún cliente vinculado.</h5>
                                <div *ngIf="clientesRelacionados.length > 0">
                                    <ul *ngFor="let item of clientesRelacionados" class="list-unstyled mb-2">
                                        <li class="my-1" >
                                            <div  style=" display: flex; flex-direction: row; width: 100%;">
                                                <div style="width: 70%;">
                                                    <h5 class="tag__description mt-1 text-left">{{ item.nombre }}</h5>
                                                    <h4 class="tag__title mt-1 text-left">{{ item.relationship }} <span *ngIf="item.new_key !== ''">-</span> {{item.new_key}}</h4>
                                                </div>
                                                <div>
                                                    <a (click)="onSelectClient(item)" >
                                                        <img src="assets/img/dashboardPage/view.svg">
                                                    </a>
                                                </div>
                                                <div style="padding-left: 10%;">
                                                    <a>
                                                        <i class="fas fa-trash-alt" data-dismiss="modal" aria-label="Close" (click)=openModalDeleteRelation(askModalRel,item.id,item.nombre,item.relationship)></i>
                                                    </a>
                                                </div>

                                            </div>
      

                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <hr class="mt-2">
                            <form id="form__third__column" #formDirective="ngForm" [formGroup]="newForm" (ngSubmit)="onSubmit(newForm, formDirective)" class=" mt-3 w-100" style="display: flex;" novalidate>
                                <h4 class="tag__title text-left">Vincular otro cliente:</h4>
                                <input formControlName="clientName" class="form-control input__class mr-sm-0 mt-2" matInput [matAutocomplete]="auto" type="search" placeholder="Buscar Cliente">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngFor="let cliente of filteredOptions | async" [value]="cliente">
                                        {{cliente.nombre}}
                                    </mat-option>
                                </mat-autocomplete>
                                <!-- ERRORS -->
                                <div class="errors__div" *ngIf="newForm.controls.clientName.invalid && (newForm.controls.clientName.dirty || newForm.controls.clientName.touched)">
                                    <span *ngIf="newForm.controls.clientName.errors.required">
                                    Es necesario cliente a asociar.
                                </span>
                                </div>

                                <h4 class="mt-2 tag__title text-left">Relación: </h4>
                                <mat-form-field appearance="legacy">
                                    <mat-select formControlName="selected"  (onSelectionChange)="get($event)">
                                        <mat-option *ngFor="let item of relationshipTags"  (click)="get(item)" [value]="item">{{ item.relationship}}</mat-option>
                                        
                                    </mat-select>
                                </mat-form-field>

                                <div *ngIf="indOtro">
                                    
                                   <mat-form-field class="example-full-width">
                                    <input matInput placeholder="Ingrese la relación" formControlName="dataOtro" name="dataOtro" >
                                  </mat-form-field>
                                </div>

                                <!-- ERRORS -->
                                <div class="errors__div" id="second__error" *ngIf="newForm.controls.selected.invalid && (newForm.controls.selected.dirty || newForm.controls.selected.touched)">
                                    <span *ngIf="newForm.controls.selected.errors.required">
                                    Es necesario establecer una relación.
                                </span>
                                </div>
                                <button class="btn btn__form" [disabled]="!newForm.valid" type="submit"> 
                                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="spinLoader"> </i>
                                    {{spinLoader? 'Creando..' : 'Crear Relación'}}</button>
                            </form>
                            <div *ngIf="isClickDate === false">
                                <hr class="mt-2">
                                <div class="input-group justify-content-center">
                                    <button type="button" [disabled]="spinLoader" class="btn btn-outline-secondary btn-sm" (click)="changeClickDate()">{{spinLoader? 'Guardando..' : 'Agregar fecha especial:'}} <i class="far fa-calendar-plus"></i></button>
                                </div>
                            </div>
                            <div *ngIf="isClickDate === true">
                                <hr class="mt-2">
                                <h4 class="tag__title mt-2 text-left">Seleccionar fecha: </h4>
                                <mat-form-field class="w-100" appearance="legacy">
                                    <input id="fecha__input" [(ngModel)]="fecha" name="fecha" matInput [matDatepicker]="picker">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <div class="errors__div" *ngIf="isDateInput">
                                        <span>
                                            Es necesario seleccionar una fecha.
                                        </span>
                                    </div>
                                    <h4 class="mt-2 tag__title text-left">Tipo de fecha: </h4>
                                    <mat-select [(ngModel)]="type_fecha">
                                        <mat-option *ngFor="let item of datesList" [value]="item.id">{{ item.titulo}}</mat-option>
                                    </mat-select>
                                    <div class="errors__div" *ngIf="isDateTypeInput">
                                        <span>
                                            Es necesario seleccionar un tipo de  fecha.
                                        </span>
                                    </div>
                                </mat-form-field>
                                <button [disabled]="spinLoader" (click)="agregarFecha()" class="btn d-block w-100 btn__form" type="button"> 
                                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="spinLoader"> </i>
                                    {{spinLoader? 'Guardando..' : 'Agregar Fecha'}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <ng-template #askModal let-c="close" let-d="dismiss" let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Desea eliminar esta fecha: </h4>
            <button style="outline: none !important;" class="close button_modal" aria-hidden="close" type="button" (click)="d('Dismissed Click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="center_date">

            <h4>{{title_registro}}</h4>
            <h4>{{fecha_registro}}</h4>
        </div>
        <!-- <div class="modal-spinner">
            <i class="fa fa-spinner fa-spin mr-2"> </i>
             {{spinLoader? 'Buscando..' : 'Ok'}} 
        </div> -->

        <div class="modal-footer">
            <button class="btn button__modal__class button_modal" type="button" (click)="c('Cancel Click')">Cancelar</button>
            <button class="btn button__modal__class button_modal" (click)="onDeleteDate()">{{spinLoaderDelete? 'Eliminando..' : 'Eliminar'}}</button>
            <!-- <button class="btn button__modal__class" (click)="register()">{{spinLoader? 'Buscando..' : 'Buscar'}}</button> -->
        </div>
    </ng-template>

</div>

<div>
    <ng-template #askModalRel let-c="close" let-d="dismiss" let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Desea eliminar esta relación: </h4>
            <button style="outline: none !important;" class="close button_modal" aria-hidden="close" type="button" (click)="d('Dismissed Click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="center_date">

            <h4>{{cliente_registro}}</h4>
            <h4>{{relacion_registro}}</h4>
        </div>

        <div class="modal-footer">
            <button class="btn button__modal__class button_modal" type="button" (click)="c('Cancel Click')">Cancelar</button>
            <button class="btn button__modal__class button_modal" (click)="onDeleteRelation()">{{spinLoaderDelete? 'Eliminando..' : 'Eliminar'}}</button>
            <!-- <button class="btn button__modal__class" (click)="register()">{{spinLoader? 'Buscando..' : 'Buscar'}}</button> -->
        </div>
    </ng-template>

</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-climbing-dot">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>